import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import { v4 as uuidv4 } from 'uuid';

import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';

const nameSchema = z.string().min(1, 'Name is required.');
const countrySchema = z
  .string()
  .regex(/^[A-Z]{2}$/, 'Country must be a valid ISO 3166-1 alpha-2 code.');
const billingDetailsSchema = z.object({
  name: nameSchema,
  city: z.string().min(1, 'City is required.'),
  country: countrySchema,
  line1: z.string().min(1, 'Address Line 1 is required.'),
  line2: z.string().optional(),
  district: z.string().min(1, 'State/Province/District is required.'),
  postalCode: z.string().min(1, 'Postal Code is required.'),
});

const bankAddressSchema = z.object({
  bankName: z.string().min(1, 'Bank Name is required.'),
  city: z.string().min(1, 'City is required.'),
  country: countrySchema,
  line1: z.string().min(1, 'Address Line 1 is required.'),
  line2: z.string().optional(),
  district: z.string().min(1, 'State/Province/District is required.'),
});

const accountNumberSchema = z
  .string()
  .regex(/^\d{8,12}$/, 'Account Number must be 8 to 12 digits.');
const routingNumberSchema = z
  .string()
  .regex(/^\d{9}$/, 'Routing Number must be 9 digits.');

interface IAccountLinkFormProps {
  onBackClick: () => void;
  styles: {
    fontFamily: string | undefined;
    borderRadius: string | undefined;
    color: string | undefined;
    buttonColor: string | undefined;
    secondaryColor: string | undefined;
  };
}

const AccountLinkForm: React.FC<IAccountLinkFormProps> = ({
  styles: { fontFamily, borderRadius, color, buttonColor, secondaryColor },
}) => {
  const [billingDetails, setBillingDetails] = useState({
    name: '',
    city: '',
    country: '',
    line1: '',
    line2: '',
    district: '',
    postalCode: '',
  });

  const [bankAddress, setBankAddress] = useState({
    bankName: '',
    city: '',
    country: '',
    line1: '',
    line2: '',
    district: '',
  });

  const [idempotencyKey, setIdempotencyKey] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [success, setSuccess] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    circle: { getWireBanks, linkWireBankAccount },
  } = useFantomStarterAdminApi();

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    schema: z.ZodSchema<any>,
    fieldName: string,
    targetState: React.Dispatch<React.SetStateAction<any>>
  ) => {
    const { value } = e.target;
    try {
      schema.parse(value);
      setErrors((prev) => ({ ...prev, [fieldName]: '' }));
    } catch (err: any) {
      if (err instanceof z.ZodError) {
        setErrors((prev) => ({ ...prev, [fieldName]: err.errors[0].message }));
      }
    }
    if (fieldName === 'accountNumber') {
      setAccountNumber(value);
    } else if (fieldName === 'routingNumber') {
      setRoutingNumber(value);
    } else if (targetState) {
      targetState((prevDetails: any) => ({
        ...prevDetails,
        [fieldName]: value,
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrors({});
    setSuccess('');
    setIsSubmitting(true);

    try {
      billingDetailsSchema.parse(billingDetails);
      bankAddressSchema.parse(bankAddress);
      accountNumberSchema.parse(accountNumber);
      routingNumberSchema.parse(routingNumber);

      const circleSeller = await linkWireBankAccount({
        idempotencyKey,
        accountNumber,
        routingNumber,
        billingDetails,
        bankAddress,
      });
      if (circleSeller.status === 201) {
        setSuccess('Billing and bank information submitted successfully!');
      }
    } catch (err: any) {
      if (err instanceof z.ZodError) {
        const validationErrors: Record<string, string> = {};
        err.errors.forEach((e) => {
          if (e.path.length > 0) {
            validationErrors[e.path[0] as string] = e.message;
          }
        });
        setErrors(validationErrors);
      } else {
        setErrors({ submit: 'Error: ' + err.message });
      }
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    setIdempotencyKey(uuidv4());
    getWireBanks().then((wireBanks) => {
      console.log(wireBanks);
    });
  }, []);

  return (
    <section>
      <form
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0px',
        }}
      >
        <h3 className=" border-b-[1px] mb-2">Billing Details</h3>
        {[
          {
            label: 'Name',
            name: 'name',
            type: 'text',
            value: billingDetails.name,
            schema: nameSchema,
            placeholder: 'Satoshi Nakamoto',
            targetState: setBillingDetails,
          },
          {
            label: 'City',
            name: 'city',
            type: 'text',
            value: billingDetails.city,
            schema: billingDetailsSchema.shape.city,
            placeholder: 'Boston',
            targetState: setBillingDetails,
          },
          {
            label: 'Country',
            name: 'country',
            type: 'text',
            value: billingDetails.country,
            schema: billingDetailsSchema.shape.country,
            placeholder: 'US',
            targetState: setBillingDetails,
          },
          {
            label: 'Address Line 1',
            name: 'line1',
            type: 'text',
            value: billingDetails.line1,
            schema: billingDetailsSchema.shape.line1,
            placeholder: '100 Money Street',
            targetState: setBillingDetails,
          },
          {
            label: 'Address Line 2',
            name: 'line2',
            type: 'text',
            value: billingDetails.line2,
            schema: billingDetailsSchema.shape.line2,
            placeholder: 'Suite 1',
            targetState: setBillingDetails,
          },
          {
            label: 'State/Province/District',
            name: 'district',
            type: 'text',
            value: billingDetails.district,
            schema: billingDetailsSchema.shape.district,
            placeholder: 'MA',
            targetState: setBillingDetails,
          },
          {
            label: 'Postal Code',
            name: 'postalCode',
            type: 'text',
            value: billingDetails.postalCode,
            schema: billingDetailsSchema.shape.postalCode,
            placeholder: '01234',
            targetState: setBillingDetails,
          },
        ].map(
          ({ label, name, type, value, schema, placeholder, targetState }) => (
            <div key={name}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <label
                  style={{
                    fontSize: '14px',
                    width: '160px',
                    flexShrink: 0,
                  }}
                >
                  {label}
                </label>
                <div style={{ flex: 1 }}>
                  <input
                    type={type}
                    name={name}
                    value={value}
                    onChange={(e) =>
                      handleInputChange(e, schema, name, targetState)
                    }
                    required={name !== 'line2'}
                    placeholder={placeholder}
                    style={{
                      background: 'transparent',
                      color,
                      fontSize: '14px',
                      padding: '8px 24px',
                      border: `1px solid ${color}`,
                      outline: 'none',
                      borderRadius,
                    }}
                  />
                </div>
              </div>
              {errors[name] && (
                <p style={{ color: 'red', margin: '4px 0 0' }}>
                  {errors[name]}
                </p>
              )}
            </div>
          )
        )}

        <h3 className=" border-b-[1px] mb-2">Bank Address</h3>
        {[
          {
            label: 'Bank Name',
            name: 'bankName',
            type: 'text',
            value: bankAddress.bankName,
            schema: bankAddressSchema.shape.bankName,
            placeholder: 'SAN FRANCISCO',
            targetState: setBankAddress,
          },
          {
            label: 'City',
            name: 'city',
            type: 'text',
            value: bankAddress.city,
            schema: bankAddressSchema.shape.city,
            placeholder: 'SAN FRANCISCO',
            targetState: setBankAddress,
          },
          {
            label: 'Country',
            name: 'country',
            type: 'text',
            value: bankAddress.country,
            schema: bankAddressSchema.shape.country,
            placeholder: 'US',
            targetState: setBankAddress,
          },
          {
            label: 'Address Line 1',
            name: 'line1',
            type: 'text',
            value: bankAddress.line1,
            schema: bankAddressSchema.shape.line1,
            placeholder: '100 Money Street',
            targetState: setBankAddress,
          },
          {
            label: 'Address Line 2',
            name: 'line2',
            type: 'text',
            value: bankAddress.line2,
            schema: bankAddressSchema.shape.line2,
            placeholder: 'Suite 1',
            targetState: setBankAddress,
          },
          {
            label: 'State/Province/District',
            name: 'district',
            type: 'text',
            value: bankAddress.district,
            schema: bankAddressSchema.shape.district,
            placeholder: 'CA',
            targetState: setBankAddress,
          },
        ].map(
          ({ label, name, type, value, schema, placeholder, targetState }) => (
            <div key={name}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <label
                  style={{
                    fontSize: '14px',
                    width: '160px',
                    flexShrink: 0,
                  }}
                >
                  {label}
                </label>
                <div style={{ flex: 1 }}>
                  <input
                    type={type}
                    name={name}
                    value={value}
                    onChange={(e) =>
                      handleInputChange(e, schema, name, targetState)
                    }
                    required={name !== 'line2'}
                    placeholder={placeholder}
                    style={{
                      background: 'transparent',
                      color,
                      fontSize: '14px',
                      padding: '8px 24px',
                      border: `1px solid ${color}`,
                      outline: 'none',
                      borderRadius,
                    }}
                  />
                </div>
              </div>
              {errors[name] && (
                <p style={{ color: 'red', margin: '4px 0 0' }}>
                  {errors[name]}
                </p>
              )}
            </div>
          )
        )}

        <h3 className="border-b-[1px] mb-2">Account Details</h3>
        {[
          {
            label: 'Account Number',
            name: 'accountNumber',
            type: 'number',
            value: accountNumber,
            schema: accountNumberSchema,
            placeholder: '12340010',
            targetState: setAccountNumber,
          },
          {
            label: 'Routing Number',
            name: 'routingNumber',
            type: 'number',
            value: routingNumber,
            schema: routingNumberSchema,
            placeholder: '121000248',
            targetState: setRoutingNumber,
          },
        ].map(
          ({ label, name, type, value, schema, placeholder, targetState }) => (
            <div key={name}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <label
                  style={{
                    fontSize: '16px',
                    width: '160px',
                    flexShrink: 0,
                  }}
                >
                  {label}
                </label>
                <div style={{ flex: 1 }}>
                  <input
                    type={type}
                    name={name}
                    value={String(value)}
                    onChange={(e) =>
                      handleInputChange(e, schema, name, targetState)
                    }
                    placeholder={placeholder}
                    style={{
                      background: 'transparent',
                      color,
                      fontSize: '14px',
                      padding: '8px 24px',
                      border: `1px solid ${color}`,
                      outline: 'none',
                      borderRadius,
                    }}
                  />
                </div>
              </div>
              {errors[name] && (
                <p style={{ color: 'red', margin: '4px 0 0' }}>
                  {errors[name]}
                </p>
              )}
            </div>
          )
        )}

        <button
          type="submit"
          disabled={isSubmitting}
          style={{
            fontFamily,
            fontWeight: '600',
            fontSize: '16px',
            padding: '12px 24px',
            borderRadius,
            border: 'none',
            cursor: 'pointer',
            color,
            backgroundColor: buttonColor,
          }}
        >
          {isSubmitting ? 'Submitting...' : 'Confirm'}
        </button>
        {errors.submit && (
          <p style={{ color: 'red', margin: '0px' }}>{errors.submit}</p>
        )}
        {success && <p style={{ color: 'green', margin: '0px' }}>{success}</p>}
      </form>
    </section>
  );
};

export default AccountLinkForm;
