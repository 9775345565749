import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { Footer } from '../../components/Footer';
import ringl from '../../icons/ringl.svg';
import plus from '../../icons/plus.svg';
import { Link } from 'react-router-dom';
import design from '../../icons/design.svg';

export default function Home() {
  return (
    <>
      <div className="overflow-y-hidden sm:overflow-visible bg-black">
        <div className=" h-screen sm:h-screen sm:bg-black">
          {/* Navbar */}
          <Navbar />

          <div className="pb-10">
            <div className="image-top absolute left-[185px] top-[50px]  lg:hidden">
              <img
                src={design}
                className="h-[400px] sm:h-[600px]"
                alt="Design Icon"
              />
            </div>

            <div className="content w-full">
              <div className="relative mt-28 w-2/2 sm:w-full sm:mt-[170px]">
                <div className="w-2/2 h-[320px] -mt-16 blur-3xl bg-[#4C29C880] rounded-[50%] sm:w-[529px] sm:ml-24 sm:h-[268.37px] sm:top-[165px] sm:bg-[#4C29C880] sm:rounded-[50%] sm:blur-3xl"></div>

                <div className="w-2/2 h-[320px] -mt-7 bg-[#FBC2714D] rounded-[50%] blur-3xl opacity-[.63] sm:w-[529px] sm:ml-24 sm:top-[433.7px] sm:h-[268.37px] sm:bg-[#FBC2714D] sm:rounded-[50%] sm:blur-3xl sm:opacity-[.63]"></div>
              </div>
              <div className="">
                <p className="absolute text-[35px] leading-[40px] font-[700] w-[250px] h-[400px] top-[315px] left-[125px] text-transparent bg-clip-text bg-gradient-to-r from-[#987EF5] to-[#F19571] sm:absolute sm:text-[56px] sm:leading-[56px] sm:mt-[32px] sm:font-[700] sm:w-[480px] sm:h-[400px] sm:top-[300px] sm:left-[200px] sm:text-transparent sm:bg-clip-text sm:bg-gradient-to-r from-[#987EF5] to-[#F19571]">
                  Elevate your ideas with advanced tokenization
                </p>

                <Link to="/project">
                  <button className="absolute z-10 text-white font-semibold text-base w-[200px] h-[40px] top-[490px] left-[120px] rounded-[100px] px-[32px] py-[13px] bg-gradient-to-r from-[#C25FFF] to-[#3D29D0] gap-4 drop-shadow-[8_-2px_4px_rgba(0, 0, 0, 0.25)] sm:absolute sm:z-10 sm:flex sm:text-white sm:font-semibold sm:text-2xl sm:w-[401px] sm:h-[64px] sm:top-[515px] sm:left-[192px] sm:rounded-[100px] sm:px-[32px] sm:py-[13px] sm:bg-gradient-to-r from-[#C25FFF] to-[#3D29D0] sm:gap-4 sm:drop-shadow-[8_-2px_4px_rgba(0, 0, 0, 0.25)] hover:bg-[#260af8]">
                    <img
                      src={plus}
                      className="-ml-2 h-4 sm:mt-1 sm:ml-4 sm:h-6 "
                      alt="Plus Icon"
                    />

                    <p className="absolute top-2 left-14 text-[16px] ms:absolute mt-[-1px] sm:mt-[1px] sm:text-3xl sm:px-10 ">
                      Start a token sale
                    </p>
                  </button>
                </Link>
              </div>
              <img
                className="absolute left-8 top-[12rem] sm:absolute sm:w-[121px] sm:h-[440px] sm:top-[220px] sm:left-[139px] "
                src={ringl}
                alt="Ring Icon"
              />
            </div>
            <div className="image-row sm:relative md:absolute lg:block md:right-[150px] sm:top-[650px] top-[560px] md:top-[150px] hidden">
              <img
                src={design}
                className="h-[400px] sm:h-[600px]"
                alt="Design Icon"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
