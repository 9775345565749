import { FC, useState, ChangeEvent } from 'react';
import Plus from '../../../assets/img/NewPlus.svg';
import Twitter from '../../../assets/img/Twitter.svg';
import Discord from '../../../assets/img/Discord.svg';
import Medium from '../../../assets/img/Medium.svg';
import Web from '../../../assets/img/Web.svg';

interface EditProjectCardProps {
  project: any;
  name: any;
  setName: Function;
  tags: any[];
  setTags: Function;
  logoFile: any;
  setLogoFile: Function;
  logoUrl: any;
  setLogoUrl: Function;
}

const EditProjectCard: FC<EditProjectCardProps> = ({
  project,
  name,
  setName,
  tags,
  setTags,
  logoFile,
  setLogoFile,
  logoUrl,
  setLogoUrl,
}) => {
  const [newTag, setNewTag] = useState<string>('');

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col p-5 w-full bg-black rounded-3xl backdrop-blur-[50px]">
        <header className="flex gap-5">
          <label htmlFor="logo-upload" className="cursor-pointer">
            <img
              src={logoUrl}
              alt=""
              className="shrink-0 self-start aspect-square w-[66px]"
            />
          </label>
          <input
            type="file"
            accept="image/*"
            className="hidden"
            id="logo-upload"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const file = event.target.files && event.target.files[0];
              const reader = new FileReader();
              reader.onloadend = () => {
                setLogoUrl(reader.result as string);
              };
              if (file) {
                reader.readAsDataURL(file);
                setLogoFile(file);
              } else {
                setLogoUrl(project.logo_image_url);
                setLogoFile(null);
              }
            }}
          ></input>
          <div className="flex flex-col w-full">
            <input
              type="text"
              className="w-full justify-center items-start px-2.5 py-2 text-sm text-white rounded-xl border border-solid bg-zinc-900 border-neutral-700"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="flex gap-5 items-center pr-9 mt-2">
              <img
                src={Twitter}
                alt=""
                className="cursor-pointer shrink-0 self-stretch my-auto w-4 aspect-square"
              />
              <img
                src={Discord}
                alt=""
                className="cursor-pointer shrink-0 self-stretch my-auto w-4 aspect-[0.88]"
              />
              <img
                src={Medium}
                alt=""
                className="cursor-pointer shrink-0 self-stretch my-auto aspect-[1.2] w-[18px]"
              />
              <img
                src={Web}
                alt=""
                className="cursor-pointer shrink-0 self-stretch my-auto w-4 aspect-square"
              />
            </div>
          </div>
        </header>
        <main className="flex flex-col mt-5">
          <div className="flex flex-col">
            <div className="flex gap-1.5 justify-between w-full whitespace-nowrap">
              <div className="flex gap-1.5">
                <div className="text-base font-semibold text-white">
                  $75,619
                </div>
                <div className="my-auto text-xs text-neutral-400">invested</div>
              </div>
              <div className="text-xs font-semibold text-white">51%</div>
            </div>
            <div className="flex flex-col justify-center mt-1.5 rounded-xl bg-zinc-900">
              <div className="shrink-0 h-2.5 rounded-xl" />
            </div>
            <div className="mt-1.5 text-xs text-neutral-400">12 days left</div>
          </div>
          <div className="w-full flex gap-2.5 mt-4 text-xs text-white overflow-x-scroll">
            {tags.length > 0
              ? tags.map((tag, index) => (
                  <div
                    key={index}
                    className="cursor-pointer h-fit justify-center px-3 py-2 whitespace-nowrap rounded-xl bg-zinc-900"
                    onClick={() => {
                      const newTags = tags.filter((_, i) => i !== index);
                      setTags(newTags);
                    }}
                  >
                    {tag}
                  </div>
                ))
              : null}
            <div className="h-fit whitespace-nowrap flex gap-1 justify-center px-3 py-2 font-medium text-purple-500 bg-black rounded-xl border border-purple-500 border-solid">
              <img src={Plus} alt="" className="shrink-0 w-3.5 aspect-square" />
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const tagWithoutLeadingWhitespace = newTag.replace(
                    /^\s+/,
                    ''
                  );
                  if (tagWithoutLeadingWhitespace.length > 0) {
                    setTags([...tags, tagWithoutLeadingWhitespace]);
                    setNewTag('');
                    console.log(tags);
                  }
                }}
              >
                <input
                  type="text"
                  placeholder="Add tag"
                  className="w-[64px] justify-center items-start px-[2px] text-sm text-purple-500 rounded-xl bg-black outline-none placeholder-purple-500"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                />
              </form>
            </div>
          </div>
        </main>
        <button className="justify-center items-center px-8 py-3.5 mt-5 text-base font-semibold text-white whitespace-nowrap rounded-3xl shadow-sm bg-[linear-gradient(135deg,#C25FFF_2.88%,#3D29D0_98.85%)]">
          Invest
        </button>
      </div>
    </div>
  );
};

export default EditProjectCard;
