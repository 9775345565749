import React, { useState, useEffect } from 'react';
import X from '../../assets/img/x.svg';
import Barcode from '../../assets/img/barcode.svg';
import Note from '../../assets/img/note.svg';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import TextEditor from '../TextEditor/TextEditor';
import { editorStateToFsParagraphs } from '../../utils/editor';

interface AddOrEditSummaryProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  project: any;
}

const AddOrEditSummary: React.FC<AddOrEditSummaryProps> = ({
  isOpen,
  setIsOpen,
  project,
}) => {
  const templateSummary = {
    terms: [
      {
        title: 'Investment type',
        value: '',
      },
      {
        title: 'Accepted tiers',
        value: '',
      },
      {
        title: 'Funding cap',
        value: '',
      },
      {
        title: 'Individual cap',
        value: '',
      },
      {
        title: 'Token',
        value: '',
      },
      {
        title: 'Token price',
        value: '',
      },
      {
        title: 'Tokens for sale',
        value: '',
      },
      {
        title: 'Payment accepted',
        value: '',
      },
    ],
    paragraphs: [],
  };

  const {
    getProject,
    admin: { saveProjectSummary },
  } = useFantomStarterAdminApi();
  const [initialJson, setInitialJson] = useState(templateSummary);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  useEffect(() => {
    if (project?.id) {
      getProject(project.id).then((result: any) => {
        const data = result.data.result.campaign;
        console.log(data);
        data ? setInitialJson(data) : setInitialJson(templateSummary);
        setInitialDataLoaded(true);
      });
    }
    return () => {
      setInitialDataLoaded(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const handleParagraphData = (data: any) => {
    const parsedParagraphs = editorStateToFsParagraphs(data);

    setInitialJson((prevJson) => ({
      ...prevJson,
      paragraphs: parsedParagraphs as any,
    }));
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    section: keyof typeof initialJson, // Explicitly typing section as a key of initialJson
    index: number,
    field: string
  ) => {
    const newValue = e.target.value;

    setInitialJson((prevJson) => ({
      ...prevJson,
      [section]: prevJson[section].map((item, idx) =>
        idx === index ? { ...item, [field]: newValue } : item
      ),
    }));
  };

  return (
    <>
      {isOpen && (
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div>
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-transparent outline-none focus:outline-none">
                <div className="w-full  p-[20px] sm:p-[30px] bg-zinc-900 rounded-[30px] justify-center sm:justify-start gap-[30px] inline-flex">
                  <div className="Frame5729 flex-col justify-start items-start gap-[30px] inline-flex">
                    <div className="Frame15 self-stretch  justify-between items-center inline-flex">
                      <div className="Label text-white text-xl font-semibold font-['SF Pro Display']">
                        Add or edit project summary
                      </div>
                      <button
                        type="button"
                        onClick={() => setIsOpen(false)}
                        className="Frame5702 h-6 justify-end items-start flex"
                      >
                        <div className="X w-6 h-6 relative">
                          <img src={X} className=" w-6 h-6" alt="x" />
                        </div>
                      </button>
                    </div>
                    <div className="Frame5728 justify-start items-start gap-[30px] flex flex-col lg:flex-row w-full">
                      <div className="Frame5726 flex-col justify-start items-start gap-[30px] inline-flex">
                        <div className="Frame5723 self-stretch h-[406px] flex-col justify-start items-start gap-[20px] flex">
                          <div className="Group5677 flex flex-col gap-[20px]">
                            <div className="Frame5704 justify-start items-center gap-2.5 inline-flex">
                              <div className="VuesaxBoldNote2 w-7 h-7 justify-center items-center flex">
                                <div className="VuesaxBoldNote2 w-7 h-7 relative">
                                  <img
                                    src={Note}
                                    alt={Note}
                                    className="w-7 h-7"
                                  />
                                </div>
                              </div>
                              <div className="Info flex-col justify-start items-start inline-flex">
                                <div className="Project text-center text-neutral-400 text-xs font-normal font-['SF Pro Display']">
                                  Project
                                </div>
                                <div className="StumbleUponRumbleTestV2 text-white text-xs font-normal font-['SF Pro Display']">
                                  {project.name}
                                </div>
                              </div>
                            </div>
                            <div className="Frame5705 justify-start items-center gap-2.5 inline-flex">
                              <div className="VuesaxBoldBarcode w-7 h-7 justify-center items-center flex">
                                <div className="Barcode w-7 h-7 relative">
                                  <img
                                    src={Barcode}
                                    alt={Barcode}
                                    className="w-7 h-7"
                                  />
                                </div>
                              </div>
                              <div className="Info flex-col justify-start items-start inline-flex">
                                <div className="UniqueId text-center text-neutral-400 text-xs font-normal font-['SF Pro Display']">
                                  Unique ID
                                </div>
                                <div className="A0831d223b1C4adF82ad7938483 text-white text-xs font-normal font-['SF Pro Display']">
                                  {project.id}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="Frame5678 flex-col justify-start items-start gap-3 flex">
                            <div className="Frame5734 justify-start items-start gap-5 inline-flex">
                              <div className="Frame15   flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="Label text-neutral-400 text-sm font-normal font-['SF Pro Display']">
                                  Investment type
                                </div>
                                <div className="Card w-[170px]  bg-neutral-900 rounded-[10px] border border-neutral-700 justify-start items-center inline-flex">
                                  <input
                                    name="investment_type"
                                    placeholder="Private"
                                    autoComplete="off"
                                    className="w-full h-full px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-300 text-sm font-normal focus:border-0 focus:ring-0 "
                                    value={initialJson?.terms[0].value}
                                    onChange={(e) =>
                                      handleInputChange(e, 'terms', 0, 'value')
                                    }
                                  />
                                </div>
                              </div>
                              <div className="Frame21   flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="Label text-neutral-400 text-sm font-normal font-['SF Pro Display']">
                                  Accepted tiers
                                </div>
                                <div className="Card w-[170px]  bg-neutral-900 rounded-[10px] border border-neutral-700 justify-start items-center inline-flex">
                                  <input
                                    name="accepted_tiers"
                                    placeholder="All"
                                    autoComplete="off"
                                    className="w-full h-full px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-300 text-sm font-normal focus:border-0 focus:ring-0 "
                                    value={initialJson?.terms[1].value}
                                    onChange={(e) =>
                                      handleInputChange(e, 'terms', 1, 'value')
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="Frame5736 justify-start items-start gap-5 inline-flex">
                              <div className="Frame15   flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="Label text-neutral-400 text-sm font-normal font-['SF Pro Display']">
                                  Funding cap
                                </div>
                                <div className="Card w-[170px]  bg-neutral-900 rounded-[10px] border border-neutral-700 justify-start items-center inline-flex">
                                  <input
                                    name="funding_cap"
                                    placeholder="e.g. 50000"
                                    autoComplete="off"
                                    className="w-full h-full px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-300 text-sm font-normal focus:border-0 focus:ring-0 "
                                    value={initialJson?.terms[2].value}
                                    onChange={(e) =>
                                      handleInputChange(e, 'terms', 2, 'value')
                                    }
                                  />
                                </div>
                              </div>
                              <div className="Frame21   flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="Label  text-neutral-400 text-sm font-normal font-['SF Pro Display']">
                                  Individual cap
                                </div>
                                <div className="Card w-[170px]  bg-neutral-900 rounded-[10px] border border-neutral-700 justify-start items-center inline-flex">
                                  <input
                                    name="individual_cap"
                                    placeholder="e.g. 1000"
                                    autoComplete="off"
                                    className="w-full h-full px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-300 text-sm font-normal focus:border-0 focus:ring-0 "
                                    value={initialJson?.terms[3].value}
                                    onChange={(e) =>
                                      handleInputChange(e, 'terms', 3, 'value')
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="Frame5737 justify-start items-start gap-5 inline-flex">
                              <div className="Frame15   flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="Label text-neutral-400 text-sm font-normal font-['SF Pro Display']">
                                  Token
                                </div>
                                <div className="Card w-[170px]  bg-neutral-900 rounded-[10px] border border-neutral-700 justify-start items-center inline-flex">
                                  <input
                                    name="token"
                                    placeholder="e.g. USDC"
                                    autoComplete="off"
                                    className="w-full h-full px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-300 text-sm font-normal focus:border-0 focus:ring-0 "
                                    value={initialJson?.terms[4].value}
                                    onChange={(e) =>
                                      handleInputChange(e, 'terms', 4, 'value')
                                    }
                                  />
                                </div>
                              </div>
                              <div className="Frame21   flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="Label w-[85px] text-neutral-400 text-sm font-normal font-['SF Pro Display']">
                                  Token price
                                </div>
                                <div className="Card w-[170px]  bg-neutral-900 rounded-[10px] border border-neutral-700 justify-start items-center inline-flex">
                                  <input
                                    name="token_price"
                                    placeholder="e.g. 1000"
                                    autoComplete="off"
                                    className="w-full h-full px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-300 text-sm font-normal focus:border-0 focus:ring-0 "
                                    value={initialJson?.terms[5].value}
                                    onChange={(e) =>
                                      handleInputChange(e, 'terms', 5, 'value')
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="Frame5739 justify-start items-start gap-5 inline-flex">
                              <div className="Frame15   flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="Label text-neutral-400 text-sm font-normal font-['SF Pro Display']">
                                  Token for sale
                                </div>
                                <div className="Card w-[170px]  bg-neutral-900 rounded-[10px] border border-neutral-700 justify-start items-center inline-flex">
                                  <input
                                    name="token_for_sale"
                                    placeholder="e.g. 1000"
                                    autoComplete="off"
                                    className="w-full h-full px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-300 text-sm font-normal focus:border-0 focus:ring-0 "
                                    value={initialJson?.terms[6].value}
                                    onChange={(e) =>
                                      handleInputChange(e, 'terms', 6, 'value')
                                    }
                                  />
                                </div>
                              </div>
                              <div className="Frame21   flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="Label text-neutral-400 text-sm font-normal font-['SF Pro Display']">
                                  Payment accepted
                                </div>
                                <div className="Card w-[170px]  bg-neutral-900 rounded-[10px] border border-neutral-700 justify-start items-center inline-flex">
                                  <input
                                    name="payment_accepted"
                                    placeholder="e.g. 1000"
                                    autoComplete="off"
                                    className="w-full h-full px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-300 text-sm font-normal focus:border-0 focus:ring-0 "
                                    value={initialJson?.terms[7].value}
                                    onChange={(e) =>
                                      handleInputChange(e, 'terms', 7, 'value')
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="Rectangle3326 w-px self-stretch bg-zinc-800" />
                      {!initialDataLoaded && (
                        <div className="w-[496px] h-[406px] rounded-2xl border border-neutral-700"></div>
                      )}
                      {initialDataLoaded && (
                        <TextEditor
                          initialData={initialJson.paragraphs}
                          getDatas={handleParagraphData}
                        />
                      )}
                    </div>
                    <div className="Frame5729 self-stretch  justify-between items-center inline-flex">
                      <div className="Label text-white text-xl font-semibold"></div>
                      <div className="Frame5702 h-[38px] justify-end items-start flex gap-[10px]">
                        <button
                          type="button"
                          onClick={() => {
                            setIsOpen(false);
                          }}
                          className="Button3 px-[18px] py-2.5 rounded-[60px]  justify-start items-center gap-1.5 flex"
                        >
                          <div className="Label text-center text-purple-500 text-sm font-semibold font-['SF Pro Display'] leading-[18px]">
                            Cancel
                          </div>
                        </button>
                        <button
                          onClick={(event: React.FormEvent) => {
                            event.preventDefault();
                            console.log(initialJson);
                            saveProjectSummary(project.id, initialJson);
                            setIsOpen(false);
                          }}
                          className="Button1 px-[18px] py-2.5 bg-purple-500 rounded-[20px] justify-center items-center gap-1.5 flex"
                        >
                          <div className="Label text-center text-black text-sm font-semibold font-['SF Pro Display'] leading-[18px]">
                            Save changes
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black bg-opacity-70 backdrop-blur-[2px]" />
        </div>
      )}
    </>
  );
};

export default AddOrEditSummary;
