import React from 'react';
import theme from '../../theme';
import styled from 'styled-components';

interface SpacerProps {
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

const Spacer: React.FC<SpacerProps> = ({ size = 'md' }) => {
  let s: number;
  switch (size) {
    case 'xxl':
      s = theme.spacing[7];
      break;
    case 'xl':
      s = theme.spacing[6];
      break;
    case 'lg':
      s = theme.spacing[5];
      break;
    case 'sm':
      s = theme.spacing[3];
      break;
    case 'xs':
      s = theme.spacing[2];
      break;
    case 'xxs':
      s = theme.spacing[1];
      break;
    case 'md':
    default:
      s = theme.spacing[4];
  }

  return <StyledSpacer size={s} />;
};

interface StyledSpacerProps {
  size: number;
}

const StyledSpacer = styled.div<StyledSpacerProps>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
`;

export default Spacer;
