import React, { PropsWithChildren, useState } from 'react';

export const LoginContext = React.createContext<any>(null);

export const LoginProvider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const [token, setToken] = useState(null);

  return (
    <LoginContext.Provider value={[token, setToken]}>
      {children}
    </LoginContext.Provider>
  );
};
