import { UNDO_COMMAND, REDO_COMMAND } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import UNDO from './../../../assets/img/ic_undo.svg';
import REDO from './../../../assets/img/ic_redo.svg';
export const CustomHistoryActions = () => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    // Focus the editor when the effect fires!
    editor.focus();
  }, [editor]);
  return (
    <>
      <div className="ButtonGroupHistory border-gray-200 justify-start items-start gap-0 lg:gap-0.5 flex">
        <button
          onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
          className="MenuBarButton justify-start items-start flex"
        >
          <div className="MenuBarButtonBase w-7 px-1 rounded justify-center items-center gap-0 lg:gap-0.5 flex">
            <div className="IcUndo w-5 h-5 relative">
              <img src={UNDO} className="IcUndo w-5 h-5 relative" alt="UNDO" />
            </div>
          </div>
        </button>
        <button
          onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
          className="MenuBarButton justify-start items-start flex"
        >
          <div className="MenuBarButtonBase w-7 px-1 rounded justify-center items-center gap-0 lg:gap-0.5 flex">
            <div className="IcRedo w-5 h-5 relative">
              <img src={REDO} className="IcUndo w-5 h-5 relative" alt="REDO" />
            </div>
          </div>
        </button>
      </div>
    </>
  );
};
