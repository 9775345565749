import React, { PropsWithChildren, useState } from 'react';

export const AppContext = React.createContext<any>(null);

export const AppProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [env, setEnv] = useState('test');

  return (
    <AppContext.Provider value={[env, setEnv]}>{children}</AppContext.Provider>
  );
};
