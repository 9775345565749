import React, { useState, ChangeEvent, useRef } from 'react';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import calendar from '../../icons/calendar.svg';
import useFantomStarterAdminApi, {
  FANTOM_STARTER_ADMIN_METHODS,
} from '../../hooks/useFantomStarterAdminApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

interface Step5Props {
  response: any;
  setResponse: (response: any) => void;
  onNextClick: () => void;
  onPrevlick?: () => void;
}

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const Step5: React.FC<Step5Props> = ({
  response,
  setResponse,
  onNextClick,
  onPrevlick,
}) => {
  const [wishlistStartDate, setWishlistStartDate] = useState<string | Value>(
    response.start_date_whitelist ? response.start_date_whitelist : ''
  );

  const [value, setValue] = useState<Value>(new Date());

  const [wishlistEndDate, setWishlistEndDate] = useState<string>(
    response.end_date_whitelist ? response.end_date_whitelist : ''
  );
  const [endDate, setEndDate] = useState<string>('');
  const [fundingStartDate, setFundingStartDate] = useState<string>(
    response.start_date_funding ? response.start_date_funding : ''
  );
  const [fundingEndDate, setfundingEndDate] = useState<string>(
    response.end_date_funding ? response.end_date_funding : ''
  );
  const [errors, setErrors] = useState<any>({});
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const navigate = useNavigate();

  const fantomStarterAdminApi = useFantomStarterAdminApi();
  const { createProject } = fantomStarterAdminApi.admin;

  const [inputVal, setInputVal] = useState<any>({
    name: response.name,
    description: response.description,
    tags: '',
    chain: response.chain,
    logo_image_url: response.logo_image_url,
    main_image_url: response.main_image_url,
    start_date_whitelist: response.start_date_whitelist
      ? response.start_date_whitelist
      : null,
    end_date_whitelist: response.end_date_whitelist
      ? response.end_date_whitelist
      : null,
    start_date_funding: response.start_date_funding
      ? response.start_date_funding
      : null,
    end_date_funding: response.end_date_funding
      ? response.end_date_funding
      : null,
    ido_token_symbol: response.ido_token_symbol,
    pay_token_symbol: response.pay_token_symbol,
    ratio: null,
    sale_version: response.sale_version,
    with_whitelist: response.with_whitelist,
    with_lottery: response.with_lottery,
    only_staked: response.only_staked,
    with_kyc: response.with_kyc,
  });

  const handleWishlistStartDateChange = (dates: Date[]) => {
    if (dates.length > 0) {
      const value = dates[0].toISOString();

      setWishlistStartDate(value);
      setInputVal((prevInputVal: any) => ({
        ...prevInputVal,
        start_date_whitelist: value,
      }));
      setBtnDisabled(false);
      setShowLoader(false);
    }
  };

  const handleWishlistEndDateChange = (dates: Date[]) => {
    if (dates.length > 0) {
      const value = dates[0].toISOString();
      setWishlistEndDate(value);
      setInputVal((prevInputVal: any) => ({
        ...prevInputVal,
        end_date_whitelist: value,
      }));
      setBtnDisabled(false);
      setShowLoader(false);
    }
  };

  const handleFundingStartDateChange = (dates: Date[]) => {
    if (dates.length > 0) {
      const value = dates[0].toISOString();
      setFundingStartDate(value);
      setInputVal((prevInputVal: any) => ({
        ...prevInputVal,
        start_date_funding: value,
      }));
      setBtnDisabled(false);
      setShowLoader(false);
    }
  };

  const handleFundingEndDateChange = (dates: Date[]) => {
    if (dates.length > 0) {
      const value = dates[0].toISOString();
      setfundingEndDate(value);
      setInputVal((prevInputVal: any) => ({
        ...prevInputVal,
        end_date_funding: value,
      }));
      setBtnDisabled(false);
      setShowLoader(false);
    }
  };

  const handleSubmit = async () => {
    setResponse(inputVal);
    setBtnDisabled(true);
    setShowLoader(true);
    const result = await createProject({
      ...inputVal,
      sale_version: parseInt(inputVal.sale_version),
      tags: inputVal.tags.split(','),
      header_image_url: inputVal.main_image_url,
      company: null,
      location: null,
      ratio_idotoken_per_paytoken: inputVal.ratio ? 1 / inputVal.ratio : 0,
      ratio_paytoken_per_idotoken: inputVal.ratio
        ? parseFloat(inputVal.ratio)
        : 0,
    });

    if (result.status === 200) {
      navigate('/projects');

      toast.success('Project Successfully Created!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else {
      navigate('/projects');

      toast.error('Failed to create Project', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }

    setErrors({ api: response.data.result.message });
  };

  const handlePrevClick = () => {
    setResponse(inputVal);
    if (typeof onPrevlick === 'function') {
      onPrevlick();
    }
  };

  const extractDateMonthAndTime = (timestamp: any) => {
    if (timestamp === undefined || timestamp === null) {
      return 'Not Available Now';
    }

    const dateObj = new Date(timestamp);

    const date = dateObj.getDate();
    const month = dateObj.toLocaleString('en-US', { month: 'short' });
    const time = dateObj.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });

    return `${date} ${month}, ${time}`;
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleFrameClick = () => {
    // Trigger the click event of the hidden input
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const fp = useRef(null);

  const isNotFcfs = response.with_lottery || response.with_whitelist;

  return (
    <div className="overflow-x-hidden overflow-y-hidden sm:overflow-visible w-full sm:w-fit px-5 sm:px-0">
      <div
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.03)',
          borderColor: 'rgba(255, 255, 255, 0.03)',
        }}
        className={`w-full mt-[50px] p-[20px] rounded-[30px]  sm:w-[490px] ${isNotFcfs ? 'h-[570px] sm:h-[600px]' : 'h-[380px] sm:h-[420px]'}  sm:mt-[40px] sm:p-[45px] sm:rounded-[30px] sm:border-[2px] flex-col justify-start items-start gap-[30px] inline-flex`}
      >
        <div className=" justify-start items-start inline-flex">
          <p className="text-[30px] leading-[64px]  font-[700]  text-transparent bg-clip-text bg-gradient-to-r from-[#987EF5] to-[#F19571] opacity-100 sm:text-[40px] sm:leading-[64px]  sm:font-[700]  sm:text-transparent sm:bg-clip-text sm:bg-gradient-to-r sm:from-[#987EF5] sm:to-[#F19571] sm:opacity-100">
            When’s the launch?
          </p>
        </div>

        {isNotFcfs && (
          <div className="Frame5675 w-full  sm:w-[400px]  flex-col justify-start items-start gap-2.5 inline-flex">
            <div className="ProjectWishlistDate text-violet-400 text-lg font-semibold">
              Project registration period
            </div>

            <div className="Frame5721 self-stretch  justify-between relative items-start gap-2.5 inline-flex">
              <div className="Frame5664 w-48 h-[116px] p-[12px] sm:p-[15px] bg-black bg-opacity-30 rounded-[15px] border border-white border-opacity-20 flex-col justify-between items-start gap-2.5 inline-flex ">
                <div className="VuesaxLinearCalendar justify-center items-center inline-flex">
                  <div className="Calendar  relative ">
                    <Flatpickr
                      className="w-full h-full absolute top-0 left-0 opacity-0 "
                      placeholder="Select Wishlist Start Date"
                      onChange={handleWishlistStartDateChange}
                      options={{ enableTime: true }}
                      ref={fp}
                    />

                    <img src={calendar} className="w-6 h-6" alt="Calendar" />
                  </div>
                </div>
                <div className="SelectDateAndTime self-stretch text-white text-opacity-20 text-lg font-normal">
                  {wishlistStartDate
                    ? extractDateMonthAndTime(wishlistStartDate)
                    : 'Select registration start date'}
                </div>
              </div>

              <label
                htmlFor="wishlist-end-date"
                className="Frame5664 w-48 h-[116px] p-[12px] sm:p-[15px] bg-black bg-opacity-30 rounded-[15px] border border-white border-opacity-20 flex-col justify-between items-start gap-2.5 inline-flex "
              >
                <div className="VuesaxLinearCalendar justify-center items-center inline-flex">
                  <div className="Calendar  relative ">
                    <Flatpickr
                      className="w-full h-full absolute top-0 left-0 opacity-0 "
                      placeholder="Select Wishlist End Date"
                      data-enable-time
                      onChange={handleWishlistEndDateChange}
                      options={{ enableTime: true }}
                      ref={fp}
                    />
                    <img src={calendar} className="w-6 h-6" alt="Calendar" />
                  </div>
                </div>
                <div className="SelectDateAndTime self-stretch text-white text-opacity-20 text-lg font-normal">
                  {wishlistEndDate
                    ? extractDateMonthAndTime(wishlistEndDate)
                    : 'Select registration end date'}
                </div>
              </label>
            </div>
          </div>
        )}

        <div className="Frame5676 w-full   sm:w-[400px]  flex-col justify-start items-start gap-2.5 inline-flex">
          <div className="ProjectFundingDate text-violet-400 text-lg font-semibold">
            Project sale period
          </div>
          <div className="Frame5721 self-stretch justify-between relative items-start gap-2.5 inline-flex">
            <label
              htmlFor="funding-start-date"
              className="Frame5664 w-48 h-[116px] p-[12px] sm:p-[15px] bg-black bg-opacity-30 rounded-[15px] border border-white border-opacity-20 flex-col justify-between items-start gap-2.5 inline-flex "
            >
              <div className="VuesaxLinearCalendar justify-center items-center inline-flex">
                <div className="Calendar  relative ">
                  <Flatpickr
                    className="w-full h-full absolute top-0 left-0 opacity-0 "
                    placeholder="Select Sale Start Date"
                    data-enable-time
                    onChange={handleFundingStartDateChange}
                    options={{ enableTime: true }}
                    ref={fp}
                  />

                  <img src={calendar} className="w-6 h-6" alt="Calendar" />
                </div>
              </div>
              <div className="SelectDateAndTime self-stretch text-white text-opacity-20 text-lg font-normal">
                {fundingStartDate
                  ? extractDateMonthAndTime(fundingStartDate)
                  : 'Select sale start date'}
              </div>
            </label>
            <label
              htmlFor="funding-end-date"
              className="Frame5664 w-48 h-[116px] p-[12px] sm:p-[15px] bg-black bg-opacity-30 rounded-[15px] border border-white border-opacity-20 flex-col justify-between items-start gap-2.5 inline-flex "
            >
              <div className="VuesaxLinearCalendar justify-center items-center inline-flex">
                <div className="Calendar  relative ">
                  <Flatpickr
                    className="w-full h-full absolute top-0 left-0 opacity-0 "
                    placeholder="Select Sale End Date"
                    data-enable-time
                    onChange={handleFundingEndDateChange}
                    options={{ enableTime: true }}
                    ref={fp}
                  />
                  <img src={calendar} className="w-6 h-6" alt="Calendar" />
                </div>
              </div>
              <div className="SelectDateAndTime self-stretch text-white text-opacity-20 text-lg font-normal">
                {fundingEndDate
                  ? extractDateMonthAndTime(fundingEndDate)
                  : 'Select sale end date'}
              </div>
            </label>
          </div>
        </div>

        <div className="w-full flex flex-row justify-between gap-5">
          <div className="Card w-[103px] h-[45px] px-6 py-2 rounded-[60px] border border-violet-400 justify-center items-center gap-2 inline-flex flex-shrink-0">
            <button
              onClick={handlePrevClick}
              className="Back border-violet-400 text-violet-400 text-base font-semibold flex-shrink-0"
            >
              ← Back
            </button>
          </div>
          <button
            disabled={
              (!wishlistStartDate && isNotFcfs) ||
              (!wishlistEndDate && isNotFcfs) ||
              !fundingStartDate ||
              !fundingEndDate ||
              btnDisabled
            }
            className={`Button relative sm:left-0 w-[200px] sm:w-[280px] h-[45px] px-8 py-[13px]  bg-gradient-to-r from-[#C25FFF] to-[#3D29D0]  drop-shadow-[8_-2px_4px_rgba(0, 0, 0, 0.25) z-10 ${
              (!wishlistStartDate && isNotFcfs) ||
              (!wishlistEndDate && isNotFcfs) ||
              !fundingStartDate ||
              !fundingEndDate
                ? 'opacity-50'
                : 'opacity-100'
            } rounded-[20px] shadow justify-center items-center gap-2 flex `}
            onClick={() => {
              handleSubmit();
            }}
          >
            {!showLoader ? (
              <div className="Next text-white text-base font-semibold">
                Done
              </div>
            ) : (
              <div
                className="w-6 h-6 rounded-full animate-spin
                    border-2 border-solid border-white border-t-transparent"
              ></div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step5;
