import {
  Body,
  Button,
  Heading2,
  Heading3,
  Heading4,
  Typo1,
  Typo2,
  Typo3,
} from '../../components';
import { Column, Item, Row } from '../../components/Grid/Grid';
import React, { useEffect, useState } from 'react';
import useAllProjects from '../../hooks/useAllProjects';
import Spacer from '../../components/Spacer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import FantomStarterLongLogoImg from '../../assets/img/FantomStarterLogoWithText.png';
import SwitchSelect from '../../components/Switch';
import Modal from '../../components/Modal';
import { Field, Form, Formik } from 'formik';
// import { DatePickerField } from "../Project/Project";
import styled from 'styled-components';
import JSONInput from 'react-json-editor-ajrm';
import { localeEn } from '../../utils/jsonEditor';
import useToken from '../../hooks/useToken';
import useAppSettings from '../../hooks/useAppSettings';
import useERC20Method from '../../hooks/useERC20ContractMethod';
import PageContentContainer from '../../containers/PageContentContainer';
import useWeb3Provider from '../../hooks/useWeb3Provider';
import Navbar from '../../components/Navbar/Navbar';
import { SalesCard } from '../../components/SalesCard';
import { Footer } from '../../components/Footer';
import { EmptyCard } from '../../components/EmptyCard';
import { EditModal } from '../../components/EditModal';
import { DeployContract } from '../../components/DeployContracts';
import { AddOrEditSummary } from '../../components/AddOrEditSummary';
import useDocusignApi from '../../hooks/useDocusign';
import { AccountLinkingModal } from '../../components/Modal';

const AddOrEditSummaryModal: React.FC<any> = ({
  project,
  isOpen,
  setIsOpen,
}) => {
  const templateSummary = {
    terms: [
      {
        title: 'Round',
        value: '',
      },
      {
        title: 'Accepted tiers',
        value: '',
      },
      {
        title: 'Funding cap',
        value: '',
      },
      {
        title: 'Individual cap',
        value: '',
      },
      {
        title: 'Token',
        value: '',
      },
      {
        title: 'Token price',
        value: '',
      },
      {
        title: 'Tokens for sale',
        value: '',
      },
      {
        title: 'Payment accepted',
        value: '',
      },
    ],
    paragraphs: [
      {
        title: 'How it works',
        text: 'There are 3 types of paragraphs you can add - text paragraph with a title, text paragraph without a title, image paragraph - see examples below',
      },
      {
        title: 'Paragraph with Title',
        text: 'This is a paragraph with a title',
      },
      {
        text: 'This is a paragraph without a title',
      },
      { image: 'link to image' },
    ],
  };
  const {
    getProject,
    circle: { getWireBanks },
  } = useFantomStarterAdminApi();
  const [initialJson, setInitialJson] = useState(undefined);
  const [summary, setSummary] = useState(undefined);

  useEffect(() => {
    if (project?.id) {
      getProject(project.id).then((result: any) => {
        setInitialJson(
          result?.data?.result?.campaign
            ? result.data.result.campaign
            : templateSummary
        );
      });
    }
  }, [project]);

  const handleSetIsOpen = (value: any) => {
    // setErrors({});
    setIsOpen(value);
  };

  return (
    <>
      <AddOrEditSummary
        isOpen={isOpen}
        setIsOpen={handleSetIsOpen}
        project={project}
      />
    </>
  );
};

const DeployProjectModal: React.FC<any> = ({ project, isOpen, setIsOpen }) => {
  const [isDeployed, setIsDeployed] = useState(false);
  const [isDeploying, setIsDeploying] = useState(false);
  const {
    admin: { deployContractsForProject },
  } = useFantomStarterAdminApi();
  const [errors, setErrors] = useState({});
  const { getDecimals } = useERC20Method(project?.chain || 4002);
  const validateForm = (values: any) => {
    const validationErrors = {} as any;
    if (!values.start_date_funding)
      validationErrors.start_date_funding = 'Required';
    if (!values.end_date_funding)
      validationErrors.end_date_funding = 'Required';
    if (!values.pay_token_address)
      validationErrors.pay_token_address = 'Required';
    if (!values.price_per_item) validationErrors.price_per_item = 'Required';
    if (!values.tokens_for_sale) validationErrors.tokens_for_sale = 'Required';

    if (Object.keys(validationErrors).length === 0) {
      return null;
    }

    return validationErrors;
  };

  const handleSetIsOpen = (value: any) => {
    setErrors({});
    setIsOpen(value);
  };

  return (
    <>
      <DeployContract
        isOpen={isOpen}
        setIsOpen={handleSetIsOpen}
        project={project}
      />
    </>
  );
};

const EditProjectModal: React.FC<any> = ({ isOpen, setIsOpen, project }) => {
  const handleSetIsOpen = (value: any) => {
    setIsOpen(value);
  };
  return (
    <>
      <EditModal
        isOpen={isOpen}
        setIsOpen={handleSetIsOpen}
        project={project}
      />
    </>
  );
};

const Projects: React.FC<any> = () => {
  const navigate = useNavigate();
  const { projects, refetch } = useAllProjects();
  const { getTemplateName, getTemplates } = useDocusignApi();

  const { getUser } = useToken();
  const { isProd, setEnv } = useAppSettings();
  const {
    admin: { migrateToProduction, deleteProject },
    circle: { getWireBanks },
  } = useFantomStarterAdminApi();
  const filterHeaders = [
    'actions',
    'id',
    'name',
    'chain',
    'start_date_whitelist',
    'end_date_whitelist',
    'start_date_funding',
    'end_date_funding',
    'whitelisted_count',
    'lottery_contract_address',
    'sale_contract_address',
    'paytoken_contract_address',
    'token_contract_address',
    'with_kyc',
    'only_staked',
    'sale_type',
  ];

  const user: any = getUser();
  const isAdmin: boolean = user?.role === 'admin';
  const [versionFilter, setVersionFilter] = useState<null | number>(null);
  const [selectedProject, setSelectedProject] = useState<null | string>(null);
  const [showAccountLinkingModal, setShowAccountLinkingModal] =
    useState<boolean>(false);

  const [selectedProjectSummary, setSelectedProjectSummary] = useState<
    null | string
  >(null);
  const [activeButton, setActiveButton] = useState('All');
  const [selectedProjectEdit, setSelectedProjectEdit] = useState<null | string>(
    null
  );

  const [templateHash, setTemplateHash] = useState<{
    [templateName: string]: any;
  }>({});

  const [userWireIds, setUserWireIds] = useState<string[]>([]);

  const handleButtonClick = (label: any) => {
    setActiveButton(label);
    setVersionFilter(label === 'All' ? null : label === 'NFTs' ? 3 : 4);
  };

  const sortedProjects = (projects: any[]) => {
    return projects.sort(
      (a, b) =>
        new Date(b.start_date_whitelist).getTime() -
        new Date(a.start_date_whitelist).getTime()
    );
  };

  const getSaleType = (project: any) => {
    if (project.with_lottery) {
      return 'lottery';
    }
    if (project.with_whitelist && !project.with_lottery) {
      return 'whitelist';
    }
    return 'fcfs';
  };

  const handleReceiveSummary = (id: string) => {
    setSelectedProjectSummary(id);
  };

  const handleReceiveProject = (id: string) => {
    setSelectedProject(id);
  };

  const handleEditProject = (id: string) => {
    setSelectedProjectEdit(id);
  };

  const projectSelected = projects.find(
    (project) => project.id === selectedProjectEdit
  );

  const projectToDeploy = projects.find(
    (project) => project.id === selectedProject
  );

  useEffect(() => {
    getTemplates().then((result: any) => {
      if (!result || !result.envelopeTemplates) return;
      const templates: any[] = result.envelopeTemplates;
      const hash: { [templateName: string]: any } = {};
      templates.forEach((template) => (hash[template.name] = template));
      setTemplateHash(hash);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getWireBanks().then((result: any) => {
      if (result.status === 200) {
        setUserWireIds(result.data ? result.data : []);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-black min-h-screen">
      <Navbar />
      {projectToDeploy && (
        <DeployProjectModal
          isOpen={!!selectedProject}
          setIsOpen={() => setSelectedProject(null)}
          project={projectToDeploy}
        />
      )}
      <AddOrEditSummaryModal
        isOpen={!!selectedProjectSummary}
        setIsOpen={() => setSelectedProjectSummary(null)}
        project={projects.find(
          (project) => project.id === selectedProjectSummary
        )}
      />

      {projectSelected && (
        <EditProjectModal
          isOpen={!!selectedProjectEdit}
          setIsOpen={() => setSelectedProjectEdit(null)}
          project={projectSelected}
        />
      )}

      {showAccountLinkingModal && (
        <AccountLinkingModal
          onClose={() => {
            setShowAccountLinkingModal(false);
          }}
          onDone={() => console.log('Done')}
        />
      )}

      <div className="flex flex-col sm:flex-row">
        <div className="p-5 sm:w-[25%]  sm:top-[40px] bg-black ">
          <div className="filter_container absolute sm:relative  sm:left-[80px] sm:top-[17px] sm:text-neutral-400 text-base font-semibold hidden sm:block">
            Filters:
          </div>

          <div className="SegmentedControl sm:w-[174px] sm:p-0.5 sm:left-[100px] sm:top-[150px] absolute bg-zinc-500 bg-opacity-25 rounded-[20px] justify-center items-center inline-flex">
            <button
              className={`Button1 grow shrink basis-0 self-stretch px-2.5 py-[3px] rounded-[20px] justify-start items-center flex ${
                activeButton === 'All' ? 'bg-purple-500' : ''
              } ${activeButton === 'All' ? 'text-black' : 'text-white'}`}
              onClick={() => handleButtonClick('All')}
            >
              <div
                className={`Label grow shrink basis-0 h-[18px] text-center  text-[13px] font-semibold leading-[18px]`}
              >
                All
              </div>
            </button>

            <button
              className={`Button2 grow shrink basis-0 self-stretch px-2.5 py-[3px] rounded-[20px] justify-start items-center flex ${
                activeButton === 'Tokens' ? 'bg-purple-500' : ''
              } ${activeButton === 'Tokens' ? 'text-black' : 'text-white'}`}
              onClick={() => handleButtonClick('Tokens')}
            >
              <div
                className={`Label grow shrink basis-0 h-[18px] text-center  text-[13px] font-semibold  leading-[18px]`}
              >
                Tokens
              </div>
            </button>

            <button
              className={`Button3 grow shrink basis-0 self-stretch px-2.5 py-[3px] rounded-[20px] justify-start items-center flex ${
                activeButton === 'NFTs' ? 'bg-purple-500' : ''
              } ${activeButton === 'NFTs' ? 'text-black' : 'text-white'}`}
              onClick={() => handleButtonClick('NFTs')}
            >
              <div
                className={`Label grow shrink basis-0 h-[18px] text-center  text-[13px] font-semibold  leading-[18px]`}
              >
                NFTs
              </div>
            </button>
          </div>

          {isAdmin && (
            <div className="absolute sm:relative  sm:left-[80px] sm:top-[100px] sm:text-neutral-400 text-base font-semibold hidden sm:flex gap-4 items-center">
              <h5>* Prod Only</h5>
              <div className="relative self-stretch justify-between items-start gap-2.5 inline-flex">
                <div
                  className="relative inline-block"
                  onClick={(e) => {
                    e.preventDefault();
                    setEnv(isProd ? 'test' : 'prod');
                    refetch();
                  }}
                >
                  <div
                    className={`peer pl-[22.35px] pr-[1.35px] py-[1.35px] w-[42px] h-[21px] cursor-pointer appearance-none rounded-full  ${
                      isProd ? 'bg-purple-500' : 'bg-neutral-700'
                    }  `}
                  ></div>
                  <span
                    className={`pointer-events-none absolute  top-[1.5px] block w-[18.29px] h-[18.29px] rounded-full bg-white transition-all duration-200 ${
                      isProd
                        ? 'transform translate-x-[22px]'
                        : 'transform translate-x-[0px]'
                    }`}
                  />
                </div>
              </div>
            </div>
          )}

          {userWireIds.length === 0 && (
            <div className="absolute sm:relative  sm:left-[80px] sm:top-[100px] sm:text-neutral-400 text-base font-semibold hidden sm:flex gap-4 items-center">
              <button
                onClick={() => {
                  setShowAccountLinkingModal(true);
                }}
              >
                {`${userWireIds.length > 0 ? 'Manage' : 'Link'} bank account →`}
              </button>
            </div>
          )}
        </div>

        <div
          className={`flex justify-center items-center sm:w-[75%] mt-10 ${
            projects.length === 0 ||
            (versionFilter === 3 &&
              projects.filter(
                (project) =>
                  activeButton === 'NFTs' && project.sale_version === 3
              ).length === 0) ||
            (versionFilter === 4 &&
              projects.filter(
                (project) =>
                  activeButton === 'Tokens' && project.sale_version === 4
              ).length === 0)
              ? 'hidden'
              : ''
          }`}
        >
          <div className="grid p-5 grid-cols-1 lg:grid-cols-2 gap-4 ">
            {projects.length > 0 &&
              versionFilter === null &&
              projects?.map((project) => (
                <SalesCard
                  project={project}
                  templateHash={templateHash}
                  chain={project?.chain}
                  id={project?.id}
                  saleVersion={project?.sale_version}
                  kyc={project?.with_kyc}
                  projectName={project?.name}
                  image={project?.logo_image_url}
                  start_date_funding={project?.start_date_funding}
                  end_date_funding={project?.end_date_funding}
                  total_raised={project?.total_raised}
                  projectType={getSaleType(project)}
                  wishlistStartDate={project?.start_date_whitelist}
                  wishlistEndDate={project?.end_date_whitelist}
                  saleContractAddress={project?.sale_contract_address}
                  idoTokenAddress={project?.token_contract_address}
                  payTokenAddress={project?.paytoken_contract_address}
                  capTotal={project?.cap_total_dollars}
                  key={project?.id}
                  selectedProject={handleReceiveProject}
                  selectedPrjectSummary={handleReceiveSummary}
                  handleEdit={handleEditProject}
                  openAccountLinkModal={() => {
                    setShowAccountLinkingModal(true);
                  }}
                />
              ))}

            {projects.length > 0 &&
              versionFilter === 4 &&
              projects
                .filter(
                  (project) =>
                    activeButton === 'Tokens' && project.sale_version === 4
                )

                .map((project) => (
                  <SalesCard
                    project={project}
                    templateHash={templateHash}
                    chain={project?.chain}
                    id={project?.id}
                    saleVersion={project?.sale_version}
                    kyc={project?.with_kyc}
                    projectName={project?.name}
                    image={project?.logo_image_url}
                    start_date_funding={project?.start_date_funding}
                    end_date_funding={project?.end_date_funding}
                    total_raised={project?.total_raised}
                    projectType={getSaleType(project)}
                    wishlistStartDate={project?.start_date_whitelist}
                    wishlistEndDate={project?.end_date_whitelist}
                    saleContractAddress={project?.sale_contract_address}
                    idoTokenAddress={project?.token_contract_address}
                    payTokenAddress={project?.paytoken_contract_address}
                    capTotal={project?.cap_total_dollars}
                    key={project?.id}
                    selectedProject={handleReceiveProject}
                    selectedPrjectSummary={handleReceiveSummary}
                    handleEdit={handleEditProject}
                    openAccountLinkModal={() => {
                      setShowAccountLinkingModal(true);
                    }}
                  />
                ))}

            {projects.length > 0 &&
              versionFilter === 3 &&
              projects
                .filter(
                  (project) =>
                    activeButton === 'NFTs' && project.sale_version === 3
                )

                .map((project) => (
                  <SalesCard
                    project={project}
                    templateHash={templateHash}
                    chain={project?.chain}
                    id={project?.id}
                    saleVersion={project?.sale_version}
                    kyc={project?.with_kyc}
                    projectName={project?.name}
                    image={project?.logo_image_url}
                    start_date_funding={project?.start_date_funding}
                    end_date_funding={project?.end_date_funding}
                    total_raised={project?.total_raised}
                    projectType={getSaleType(project)}
                    wishlistStartDate={project?.start_date_whitelist}
                    wishlistEndDate={project?.end_date_whitelist}
                    saleContractAddress={project?.sale_contract_address}
                    idoTokenAddress={project?.token_contract_address}
                    payTokenAddress={project?.paytoken_contract_address}
                    capTotal={project?.cap_total_dollars}
                    key={project?.id}
                    selectedProject={handleReceiveProject}
                    selectedPrjectSummary={handleReceiveSummary}
                    handleEdit={handleEditProject}
                    openAccountLinkModal={() => {
                      setShowAccountLinkingModal(true);
                    }}
                  />
                ))}
          </div>
        </div>

        <div
          className={`flex justify-center items-center mt-10 sm:w-[47%] ${(projects.length === 0 && versionFilter !== 3 && versionFilter !== 4) || (versionFilter === 3 && activeButton === 'NFTs' && projects.filter((project) => project.sale_version === 3).length === 0) || (versionFilter === 4 && activeButton === 'Tokens' && projects.filter((project) => project.sale_version === 4).length === 0) ? '' : 'hidden'}`}
        >
          <div className="grid p-5 grid-cols-1 ">
            {((versionFilter === null && projects?.length === 0) ||
              (versionFilter === 3 &&
                activeButton === 'NFTs' &&
                projects.filter((project) => project.sale_version === 3)
                  .length === 0) ||
              (versionFilter === 4 &&
                activeButton === 'Tokens' &&
                projects.filter((project) => project.sale_version === 4)
                  .length === 0)) && <EmptyCard />}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Projects;
