import {
  type Chain,
  fantomTestnet,
  mainnet,
  polygon,
  polygonZkEvm,
  avalanche,
  bsc,
  zkSync,
  arbitrum,
  arbitrumNova,
  optimism,
  linea,
  mantle,
  base,
  scroll,
  manta,
  metis,
  rootstock,
  kava,
  boba,
  fantom,
  moonbeam,
  celo,
} from 'wagmi/chains';

export const blastSepolia = {
  id: 168587773,
  name: 'Blast Sepolia',
  network: 'blast-sepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://sepolia.blast.io'] },
    default: { http: ['https://sepolia.blast.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'BlastScan', url: 'https://testnet.blastscan.io' },
    default: { name: 'BlastScan', url: 'https://testnet.blastscan.io' },
  },
} as const satisfies Chain;

export const blast = {
  id: 81457,
  name: 'Blast',
  network: 'blast',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.blast.io'] },
    default: { http: ['https://rpc.blast.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'BlastScan', url: 'https://blastscan.io' },
    default: { name: 'BlastScan', url: 'https://blastscan.io' },
  },
} as const satisfies Chain;

export const fraxtal = {
  id: 252,
  name: 'Fraxtal',
  network: 'fraxtal',
  nativeCurrency: {
    decimals: 18,
    name: 'Frax Ether',
    symbol: 'frxETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.frax.com'] },
    default: { http: ['https://rpc.frax.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'FraxtalScan', url: 'https://fraxscan.com' },
    default: { name: 'FraxtalScan', url: 'https://fraxscan.com' },
  },
} as const satisfies Chain;

export const sonicTestnet = {
  id: 64165,
  name: 'Sonic Testnet',
  network: 'sonic-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'FTM',
    symbol: 'FTM',
  },
  rpcUrls: {
    public: { http: ['https://rpc.sonic.fantom.network/'] },
    default: { http: ['https://rpc.sonic.fantom.network/'] },
  },
  blockExplorers: {
    etherscan: {
      name: 'SonicScan',
      url: 'https://public-sonic.fantom.network',
    },
    default: { name: 'SonicScan', url: 'https://public-sonic.fantom.network' },
  },
} as const satisfies Chain;

export const polygonAmoy = {
  id: 80002,
  name: 'Polygon Amoy',
  network: 'polygon-amoy',
  nativeCurrency: {
    decimals: 18,
    name: 'MATIC',
    symbol: 'MATIC',
  },
  rpcUrls: {
    public: { http: ['https://rpc-amoy.polygon.technology/'] },
    default: { http: ['https://rpc-amoy.polygon.technology/'] },
  },
  blockExplorers: {
    etherscan: {
      name: 'PolygonScan',
      url: 'https://amoy.amoy.polygonscan.com',
    },
    default: { name: 'PolygonScan', url: 'https://amoy.polygonscan.com' },
  },
} as const satisfies Chain;

export const defaultChain = fantom;

export const chains = [
  fantomTestnet,
  mainnet,
  polygon,
  polygonZkEvm,
  avalanche,
  bsc,
  zkSync,
  arbitrum,
  arbitrumNova,
  optimism,
  linea,
  mantle,
  base,
  scroll,
  manta,
  metis,
  rootstock,
  kava,
  boba,
  fantom,
  moonbeam,
  celo,
  fraxtal,
  sonicTestnet,
  polygonAmoy,
];

export const chainIdToViemChainMap: {
  [chainId: number]: Chain;
} = {
  81457: blast,
  168587773: blastSepolia,
  4002: fantomTestnet,
  252: fraxtal,
  1: mainnet,
  137: polygon,
  43114: avalanche,
  56: bsc,
  42161: arbitrum,
  421611: arbitrumNova,
  10: optimism,
  59144: linea,
  5000: mantle,
  8453: base,
  534352: scroll,
  169: manta,
  1088: metis,
  30: rootstock,
  2222: kava,
  250: fantom,
  1284: moonbeam,
  42220: celo,
  80002: polygonAmoy,
};
