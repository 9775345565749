import React from 'react';
import withValidLogin from './hoc/withValidLogin';
import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { hooks as metaMaskHooks, metaMask } from './connectors/metamask';
import { ApiDataProvider } from './context/ApiDataProvider';
import { LoginProvider } from './context/LoginProvider';
import { Route, Routes } from 'react-router-dom';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { WagmiConfig } from 'wagmi';

import Home from './views/Home';
import Projects from './views/Projects';
import Project from './views/Project';
import { AppProvider } from './context/AppProvider';
import { ToastContainer } from 'react-toastify';
import { chains } from './config/wagmiConfig';

const ProjectWithAuth = withValidLogin(Project);
const ProjectsWithAuth = withValidLogin(Projects);

const projectId = '4f962eae4856f771802aaf869f880015';

const metadata = {
  name: 'FutureStarter',
  description: 'Elevate your ideas with advanced tokenization',
  url: 'https://futurestarter.xyz/',
};

const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeVariables: {
    '--w3m-accent': 'linear-gradient(134.5deg, #C25FFF 2.88%, #3D29D0 98.85%)',
    '--w3m-z-index': 9999,
  },
});

const Providers: React.FC<any> = ({ children }) => {
  const connectors: [MetaMask, Web3ReactHooks][] = [[metaMask, metaMaskHooks]];

  return (
    <WagmiConfig config={wagmiConfig}>
      <Web3ReactProvider connectors={connectors}>
        <LoginProvider>
          <AppProvider>
            <ApiDataProvider>{children}</ApiDataProvider>
          </AppProvider>
        </LoginProvider>
      </Web3ReactProvider>
    </WagmiConfig>
  );
};

const App = () => {
  return (
    <Providers>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<ProjectsWithAuth />} />
        <Route path="/project" element={<ProjectWithAuth />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Providers>
  );
};

export default App;
