import React, { useState, ChangeEvent, useEffect } from 'react';
import upload from '../../icons/upload.svg';

interface Step3Props {
  response: any;
  setResponse: (response: any) => void;
  onNextClick: () => void;
  onPrevlick?: () => void;
}

const Step3: React.FC<Step3Props> = ({
  response,
  setResponse,
  onNextClick,
  onPrevlick,
}) => {
  const [inputVal, setInputVal] = useState<any>({
    name: response.name,
    description: response.description,
    tags: '',
    chain: response.chain,
    logo_image_url: response.logo_image_url ? response.logo_image_url : '',
    main_image_url: response.main_image_url ? response.main_image_url : '',
    start_date_whitelist: response.start_date_whitelist
      ? response.start_date_whitelist
      : null,
    end_date_whitelist: response.end_date_whitelist
      ? response.end_date_whitelist
      : null,
    start_date_funding: response.start_date_funding
      ? response.start_date_funding
      : null,
    end_date_funding: response.end_date_funding
      ? response.end_date_funding
      : null,
    ido_token_symbol: response.ido_token_symbol
      ? response.ido_token_symbol
      : '',
    pay_token_symbol: response.pay_token_symbol
      ? response.pay_token_symbol
      : 'axlUDSC',
    ratio: null,
    sale_version: response.sale_version,
    with_whitelist: response.with_whitelist,
    with_lottery: response.with_lottery,
    only_staked: response.only_staked,
    with_kyc: response.with_kyc,
  });
  const [imageUrl1, setImageUrl1] = useState<string>(inputVal.logo_image_url);
  const [imgUrlName1, setImgUrlName1] = useState<string>('');
  const [imageUrl2, setImageUrl2] = useState<string>(inputVal.main_image_url);
  const [imgUrlName2, setImgUrlName2] = useState<string>('');
  let url1 = '';
  let url2 = '';
  let temp_input = {};
  const [show, setShow] = useState<boolean>(false);
  const [file1, setFile1] = useState<File | null>(null);
  const [file2, setFile2] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const uploadPreset =
    process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET ?? 'default_upload_preset';
  const cloudName =
    process.env.REACT_APP_CLOUDINARY_CLOUD_NAME ?? 'default_cloud_name';

  const handleImageChange1 = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file !== null) {
      setImgUrlName1(file?.name);
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl1(reader.result as string);
      setShow(true);
    };

    if (file) {
      reader.readAsDataURL(file);
      setShow(true);
      setFile1(file);
    } else {
      setImageUrl1('');
      setFile1(null);
      setImgUrlName1('');
    }
  };

  const handleImageChange2 = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file !== null) {
      setImgUrlName2(file?.name);
    }
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUrl2(reader.result as string);
      setShow(true);
    };

    if (file) {
      reader.readAsDataURL(file);
      setShow(true);
      setFile2(file);
    } else {
      setImageUrl2('');
      setFile2(null);
      setImageUrl2('');
    }
  };
  const handleLinkChange1 = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setImageUrl1(event.target.value);
      setInputVal((prevInputVal: any) => ({
        ...prevInputVal,
        logo_image_url: event.target.value,
      }));
    }
  };

  const handleLinkChange2 = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setImageUrl2(event.target.value);
      setInputVal((prevInputVal: any) => ({
        ...prevInputVal,
        main_image_url: event.target.value,
      }));
    }
  };

  const uploadImage1 = async () => {
    if (file1) {
      const data = new FormData();
      data.append('file', file1);
      data.append('upload_preset', uploadPreset);
      data.append('cloud_name', cloudName);
      data.append('folder', 'Cloudinary-React');

      try {
        const response1 = await fetch(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
          {
            method: 'POST',
            body: data,
          }
        );
        const res1 = await response1.json();
        url1 = await res1.secure_url;
        setInputVal((prevInputVal: any) => ({
          ...prevInputVal,
          upload_logo_image_url: url1,
        }));
      } catch (error) {
        console.log('ERROR');
      }
    }
  };

  const uploadImage2 = async () => {
    if (file2) {
      const data = new FormData();
      data.append('file', file2);
      data.append('upload_preset', uploadPreset);
      data.append('cloud_name', cloudName);
      data.append('folder', 'Cloudinary-fs');

      try {
        const response2 = await fetch(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
          {
            method: 'POST',
            body: data,
          }
        );
        const res2 = await response2.json();
        url2 = res2.secure_url;

        setInputVal((prevInputVal: any) => ({
          ...prevInputVal,
          upload_main_image_url: url2,
        }));
      } catch (error) {
        console.log('ERROR');
      }
    }
  };

  const handleNextClick = async () => {
    setIsUploading(true);
    await handleSubmit();
    setIsUploading(false);
    setResponse(temp_input);
    onNextClick();
  };
  const handleSubmit = async () => {
    await Promise.all([uploadImage1(), uploadImage2()]);

    if (file1 && !file2) {
      temp_input = {
        name: response.name,
        description: response.description,
        tags: '',
        chain: response.chain,
        logo_image_url: url1,
        main_image_url: imageUrl2,
        sale_type: '',
        start_date_whitelist: response.start_date_whitelist
          ? response.start_date_whitelist
          : null,
        end_date_whitelist: response.end_date_whitelist
          ? response.end_date_whitelist
          : null,
        start_date_funding: response.start_date_funding
          ? response.start_date_funding
          : null,
        end_date_funding: response.end_date_funding
          ? response.end_date_funding
          : null,
        ido_token_symbol: response.ido_token_symbol
          ? response.ido_token_symbol
          : '',
        pay_token_symbol: response.pay_token_symbol
          ? response.pay_token_symbol
          : 'USDC',
        ratio: null,
        sale_version: response.sale_version,
        with_whitelist: response.with_whitelist,
        with_lottery: response.with_lottery,
        only_staked: response.only_staked,
        with_kyc: response.with_kyc,
      };
    }
    if (!file1 && file2) {
      temp_input = {
        name: response.name,
        description: response.description,
        tags: '',
        chain: response.chain,
        logo_image_url: imageUrl1,
        main_image_url: url2,
        sale_type: '',
        start_date_whitelist: response.start_date_whitelist
          ? response.start_date_whitelist
          : null,
        end_date_whitelist: response.end_date_whitelist
          ? response.end_date_whitelist
          : null,
        start_date_funding: response.start_date_funding
          ? response.start_date_funding
          : null,
        end_date_funding: response.end_date_funding
          ? response.end_date_funding
          : null,
        ido_token_symbol: response.ido_token_symbol
          ? response.ido_token_symbol
          : '',
        pay_token_symbol: response.pay_token_symbol
          ? response.pay_token_symbol
          : 'USDC',
        ratio: null,
        sale_version: response.sale_version,
        with_whitelist: response.with_whitelist,
        with_lottery: response.with_lottery,
        only_staked: response.only_staked,
        with_kyc: response.with_kyc,
      };
    }
    if (!file1 && !file2) {
      temp_input = {
        name: response.name,
        description: response.description,
        tags: '',
        chain: response.chain,
        logo_image_url: imageUrl1,
        main_image_url: imageUrl2,
        sale_type: '',
        start_date_whitelist: response.start_date_whitelist
          ? response.start_date_whitelist
          : null,
        end_date_whitelist: response.end_date_whitelist
          ? response.end_date_whitelist
          : null,
        start_date_funding: response.start_date_funding
          ? response.start_date_funding
          : null,
        end_date_funding: response.end_date_funding
          ? response.end_date_funding
          : null,
        ido_token_symbol: response.ido_token_symbol
          ? response.ido_token_symbol
          : '',
        pay_token_symbol: response.pay_token_symbol
          ? response.pay_token_symbol
          : 'USDC',
        ratio: null,
        sale_version: response.sale_version,
        with_whitelist: response.with_whitelist,
        with_lottery: response.with_lottery,
        only_staked: response.only_staked,
        with_kyc: response.with_kyc,
      };
    }

    if (file1 && file2) {
      temp_input = {
        name: response.name,
        description: response.description,
        tags: '',
        chain: response.chain,
        logo_image_url: url1,
        main_image_url: url2,
        sale_type: '',
        start_date_whitelist: response.start_date_whitelist
          ? response.start_date_whitelist
          : null,
        end_date_whitelist: response.end_date_whitelist
          ? response.end_date_whitelist
          : null,
        start_date_funding: response.start_date_funding
          ? response.start_date_funding
          : null,
        end_date_funding: response.end_date_funding
          ? response.end_date_funding
          : null,
        ido_token_symbol: response.ido_token_symbol
          ? response.ido_token_symbol
          : '',
        pay_token_symbol: response.pay_token_symbol
          ? response.pay_token_symbol
          : 'USDC',
        ratio: null,
        sale_version: response.sale_version,
        with_whitelist: response.with_whitelist,
        with_lottery: response.with_lottery,
        only_staked: response.only_staked,
        with_kyc: response.with_kyc,
      };
    }

    setResponse(temp_input);
  };

  const handleBackClick = () => {
    setResponse(inputVal);
    if (typeof onPrevlick === 'function') {
      onPrevlick();
    }
  };

  return (
    <div className="overflow-x-hidden overflow-y-hidden sm:overflow-visible w-full sm:w-fit px-5 sm:px-0">
      <div
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.03)',
          borderColor: 'rgba(255, 255, 255, 0.03)',
        }}
        className="mt-[50px] w-full min-h-[450px] sm:mt-[40px] p-[20px] rounded-[30px] border-[2px]  sm:relative  sm:w-[490px] sm:min-h-[450px]  sm:p-[45px] sm:rounded-[30px] sm:border-[2px] flex-col justify-start items-start gap-[30px] inline-flex"
      >
        <div className=" justify-start items-start inline-flex">
          <p className="text-[30px] leading-[64px]  font-[700]   text-transparent bg-clip-text bg-gradient-to-r from-[#987EF5] to-[#F19571] opacity-100 sm:text-[40px] sm:leading-[64px]  sm:font-[700] sm:text-transparent sm:bg-clip-text sm:bg-gradient-to-r sm:from-[#987EF5] sm:to-[#F19571] sm:opacity-100">
            Unleash your visuals
          </p>
        </div>

        <div className="flex-col justify-start items-start gap-2.5 inline-flex w-full">
          <div>
            <p className="text-[20px] text-[#A68DFF] font-[600] sm:text-[20px]  sm:text-[#A68DFF] sm:font-[600] ">
              Project logo
            </p>
          </div>

          <label
            htmlFor="logo-upload"
            className="Card w-full sm:w-[400px] h-[168px] px-[26px] py-7 rounded-[15px] border border-zinc-500 flex-col justify-center items-center gap-2 inline-flex cursor-pointer"
          >
            <div className="VuesaxLinearDocumentUpload justify-center items-center inline-flex cursor-pointer">
              <div className="DocumentUpload relative">
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  id="logo-upload"
                  onChange={handleImageChange1}
                />
                <div className="image-container">
                  <img
                    src={upload}
                    alt="upload"
                    className={`image ${imageUrl1 ? 'hidden' : 'block'}`}
                  />
                </div>

                {imageUrl1 && (
                  <>
                    <div className=" flex items-center flex-col gap-2">
                      <img
                        src={imageUrl1}
                        alt="Preview"
                        className="h-auto w-auto rounded-[15px] max-w-[90px] max-h-[90px]"
                      />
                      <div className=" text-neutral-400 text-xs font-normal">
                        {imgUrlName1}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              className={`Frame5720 flex-col justify-center items-center gap-1 flex cursor-pointer ${
                imageUrl1 ? 'hidden' : 'block'
              }`}
            >
              <div className="DragImageHereOrBrowse">
                <span className="text-neutral-400 text-base font-semibold">
                  Drag image here or{' '}
                </span>
                <span className="text-violet-400 text-base font-semibold">
                  browse
                </span>
              </div>
              <div className="PngOrJpgMax512x512px text-neutral-400 text-xs font-normal">
                PNG or JPG (max. 512x512px)
              </div>
            </div>
          </label>
        </div>

        <div className="flex-col justify-start items-start gap-2.5 inline-flex w-full">
          <div>
            <p className="text-[20px] text-[#A68DFF] font-[600]  sm:text-[20px] sm:text-[#A68DFF] sm:font-[600] ">
              Hero image
            </p>
          </div>

          <label
            htmlFor="hero-upload"
            className="Card w-full sm:w-[400px]  h-[233px] px-[26px] py-7 rounded-[15px] border border-zinc-500 flex-col justify-center items-center gap-2 inline-flex cursor-pointer"
          >
            <div className="VuesaxLinearDocumentUpload justify-center items-center inline-flex cursor-pointer">
              <div className="DocumentUpload relative">
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  id="hero-upload"
                  onChange={handleImageChange2}
                />
                <div className="image-container">
                  <img
                    src={upload}
                    alt="upload"
                    className={`image ${imageUrl2 ? 'hidden' : 'block'}`}
                  />
                </div>
                {imageUrl2 && (
                  <div className=" flex items-center flex-col gap-2">
                    <img
                      src={imageUrl2}
                      alt="upload"
                      className="max-w-[255px] max-h-[155px] h-auto w-auto rounded-[15px]"
                    />
                    <div className=" text-neutral-400 text-xs font-normal">
                      {imgUrlName2}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`Frame5720 flex-col justify-center items-center gap-1 flex cursor-pointer ${
                imageUrl2 ? 'hidden' : 'block'
              }`}
            >
              <div className="DragImageHereOrBrowse">
                <span className="text-neutral-400 text-base font-semibold">
                  Drag image here or{' '}
                </span>
                <span className="text-violet-400 text-base font-semibold">
                  browse
                </span>
              </div>
              <div className="PngOrJpgMax512x512px text-neutral-400 text-xs font-normal">
                PNG or JPG (max. 512x512px)
              </div>
            </div>
          </label>
        </div>

        <div className="w-full flex flex-row justify-between gap-5">
          <div className="Card h-[45px] px-6 py-2 rounded-[60px] border border-violet-400 justify-center items-center gap-2 inline-flex flex-shrink-0">
            <button
              onClick={handleBackClick}
              className="Back border-violet-400 text-violet-400 text-base font-semibold flex-shrink-0"
            >
              ← Back
            </button>
          </div>
          <button
            disabled={!imageUrl1 || !imageUrl2 || isUploading}
            className={`Button relative  sm:left-[3px]  w-[180px] sm:w-[280px] h-[45px] px-8 py-[13px]  bg-gradient-to-r from-[#C25FFF] to-[#3D29D0]  drop-shadow-[8_-2px_4px_rgba(0, 0, 0, 0.25) z-10  ${
              !imageUrl1 || !imageUrl2 || isUploading
                ? 'opacity-50'
                : 'opacity-100'
            }  rounded-[20px] shadow justify-center items-center gap-2 flex `}
            onClick={() => {
              if (isUploading) return;
              handleNextClick();
              handleSubmit();
            }}
          >
            <div className="Next text-white text-base font-semibold flex flex-row items-center gap-1">
              {isUploading ? (
                <svg
                  className="animate-spin"
                  width="24"
                  height="24"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M33.5201 19.12C33.2801 14.24 30.3201 9.68 25.9201 7.6C21.1201 5.44 15.2801 6.32 11.2801 9.68C7.44009 12.88 5.68009 18.08 6.80009 22.96C7.84009 27.76 11.6001 31.68 16.3201 32.96C22.0001 34.56 27.2001 31.84 30.4001 27.2C27.5201 31.04 23.1201 33.6 18.2401 32.72C13.3601 31.84 9.36009 28.16 8.24009 23.36C7.04009 18.24 9.44009 12.88 14.0001 10.24C18.7201 7.52 25.3601 8.56 28.4801 13.2C29.2801 14.32 29.8401 15.68 30.0801 17.04C30.3201 18.16 30.2401 19.36 30.4001 20.48C30.5601 21.52 31.4401 22.88 32.6401 22.16C33.6801 21.52 33.6001 20.16 33.5201 19.12C33.6001 19.68 33.5201 18.8 33.5201 19.12Z"
                    fill="#F8FAFB"
                  />
                </svg>
              ) : null}
              {isUploading ? 'Uploading...' : 'Next'}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step3;
