import { useState } from 'react';

import polygon from '../../../icons/polygon.svg';
import ftm from '../../../icons/ftm.svg';
import eth from '../../../icons/eth.svg';
import bsc from '../../../icons/bsc.svg';
import manta from '../../../icons/manta.svg';
import blast from '../../../icons/blast.svg';
import mode from '../../../icons/mode.svg';
import base from '../../../icons/base.svg';

export interface NetworkProps {
  imageSrc: string;
  name: string;
  chainId: number;
}

export const networks: NetworkProps[] = [
  {
    imageSrc: ftm,
    name: 'FANTOM TESTNET',
    chainId: 4002,
  },
  {
    imageSrc: ftm,
    name: 'FANTOM',
    chainId: 250,
  },
  {
    imageSrc: polygon,
    name: 'POLYGON TESTNET',
    chainId: 80002,
  },
  {
    imageSrc: polygon,
    name: 'POLYGON',
    chainId: 137,
  },
  {
    imageSrc: base,
    name: 'BASE TESTNET',
    chainId: 84532,
  },
  {
    imageSrc: base,
    name: 'BASE',
    chainId: 8453,
  },
  {
    imageSrc: blast,
    name: 'BLAST TESTNET',
    chainId: 168587773,
  },
  {
    imageSrc: blast,
    name: 'BLAST',
    chainId: 81457,
  },
  {
    imageSrc: eth,
    name: 'ETHEREUM',
    chainId: 11155111,
  },
  {
    imageSrc: bsc,
    name: 'BSC',
    chainId: 97,
  },
  {
    imageSrc: manta,
    name: 'MANTA',
    chainId: 3441005,
  },
  {
    imageSrc: mode,
    name: 'MODE',
    chainId: 919,
  },
  {
    imageSrc: ftm,
    name: 'SONIC',
    chainId: 64165,
  },
];

const Network = ({ imageSrc, name }: NetworkProps) => {
  return (
    <div className="flex flex-col justify-center px-2.5 py-2 text-white whitespace-nowrap rounded-xl border border-solid bg-zinc-900 border-neutral-700">
      <div className="flex gap-5 justify-between">
        <div className="flex gap-1.5">
          <img
            loading="lazy"
            src={imageSrc}
            className="shrink-0 w-5 rounded-full aspect-square"
            alt={name}
          />
          <div>{name}</div>
        </div>
      </div>
    </div>
  );
};

interface NetworkDropdownProps {
  selectedNetwork: NetworkProps | undefined;
  setSelectedNetwork: Function;
  networks: NetworkProps[];
}

const NetworkDropdown = ({
  selectedNetwork,
  setSelectedNetwork,
  networks,
}: NetworkDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleNetworkSelect = (network: NetworkProps) => {
    setSelectedNetwork(network);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="flex items-center justify-center w-full rounded-xl border border-neutral-700 shadow-sm px-4 py-2 bg-zinc-900 text-sm font-medium text-white focus:outline-none"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded={isOpen}
          onClick={toggleDropdown}
        >
          {selectedNetwork ? (
            <div className="flex gap-1.5">
              <img
                loading="lazy"
                src={selectedNetwork.imageSrc}
                className="shrink-0 w-5 rounded-full aspect-square"
                alt={selectedNetwork.name}
              />
              <div>{selectedNetwork.name}</div>
            </div>
          ) : (
            'Select a network'
          )}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-xl shadow-lg bg-zinc-900">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {networks.map((network) => (
              <button
                key={network.name}
                className="block px-4 py-2 text-sm text-gray-700 hover:text-gray-900 w-full text-left"
                role="menuitem"
                onClick={() => handleNetworkSelect(network)}
              >
                <Network
                  imageSrc={network.imageSrc}
                  name={network.name}
                  chainId={network.chainId}
                />
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NetworkDropdown;
