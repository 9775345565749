import { BigNumber, formatFixed, parseFixed } from '@ethersproject/bignumber';

export const weiToUnit = (value: BigNumber, decimals = 18) => {
  // const result = value.mul(10000).div(BigNumber.from(10).pow(decimals));
  // return result.toNumber() / 10000;

  if (!value?._isBigNumber) {
    console.warn(`[weiToUnit] ${value} is not of type BigNumber`);
    return;
  }

  return parseFloat(formatFixed(value, decimals));
};

export const unitToWei = (value: string, decimals = 18) => {
  if (!(parseFloat(value) > 0)) {
    return BigNumber.from(0);
  }

  return parseFixed(
    value.length > decimals ? value.substr(0, decimals) : value,
    decimals
  );
};
