import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';
import useToken from '../../hooks/useToken';
import { useWeb3Modal } from '@web3modal/wagmi/react';

import box from '../../icons/box.svg';
import fs from '../../icons/fs.svg';
import home from '../../icons/home.svg';
import p_home from '../../icons/p_home.svg';
import p_box from '../../icons/p_box.svg';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>('dashboard');
  const { isConnected, isDisconnected } = useAccount();
  const { logout } = useToken();
  const { open } = useWeb3Modal();

  const handleRedirect = () => {
    window.location.href = 'https://docs.futurestarter.xyz';
  };

  const handleLinkState = (state: string) => {
    setActiveTab(state);
  };

  useEffect(() => {
    if (location.pathname === '/home') {
      setActiveTab('dashboard');
    } else if (location.pathname === '/projects') {
      setActiveTab('projects');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isDisconnected) {
      logout();
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisconnected]);

  return (
    <nav className="flex justify-between items-center w-2/2 mx-auto bg-black sm:px-32 px-5 pt-5">
      <div>
        <Link to="/home">
          <img className="w-[60px] h-[30px]" src={fs} alt="..." />
        </Link>
      </div>
      <div className="nav-links duration-500  w-auto flex items-center ">
        <ul className="flex flex-row items-center sm:gap-12 gap-3">
          <Link to="/" onClick={() => handleLinkState('dashboard')}>
            <li>
              <div className="flex hover:text-white">
                <img
                  src={`${activeTab === 'dashboard' ? p_home : home} `}
                  className="w-[28px] h-[28px] visible sm:w-[24px] sm:h-[24px] sm:invisible"
                  alt="new_img"
                />
                <a
                  className={`hidden sm:block sm:text-[19px] sm:px-1  ${
                    activeTab === 'dashboard'
                      ? 'sm:text-transparent sm:bg-clip-text sm:bg-gradient-to-r from-[#987EF5] to-[#F19571] font-bold'
                      : 'text-white font-normal'
                  }`}
                  href="_#"
                >
                  Dashboard
                </a>
              </div>
            </li>
          </Link>
          <Link to="/projects" onClick={() => handleLinkState('projects')}>
            <li>
              <div className="flex hover:text-white">
                <img
                  src={`${activeTab === 'projects' ? p_box : box} `}
                  className="w-[28px] h-[28px] visible sm:w-[24px] sm:h-[24px] sm:invisible"
                  alt="new_img1"
                />
                <a
                  className={`hidden sm:block sm:visible sm:text-[19px] sm:px-1   ${
                    activeTab === 'projects'
                      ? 'sm:text-transparent sm:bg-clip-text sm:bg-gradient-to-r from-[#987EF5] to-[#F19571] font-bold'
                      : 'text-white font-normal'
                  }`}
                  href="_#"
                >
                  Sales
                </a>
              </div>
            </li>
          </Link>
          <Link to="/">
            <li>
              <div
                className="flex hover:text-white"
                onClick={() => {
                  setActiveTab('help');
                  handleRedirect();
                }}
              >
                <a
                  className={`hidden sm:block sm:visible sm:text-[19px] sm:px-4   ${
                    activeTab === 'help'
                      ? 'sm:text-transparent sm:bg-clip-text sm:bg-gradient-to-r from-[#987EF5] to-[#F19571] font-bold'
                      : 'text-white font-normal'
                  }`}
                  href="_#"
                >
                  Help
                </a>
              </div>
            </li>
          </Link>
        </ul>
      </div>
      {isConnected ? (
        <w3m-button />
      ) : (
        <button
          onClick={() => open()}
          className="bg-gradient-to-r from-[#C25FFF] to-[#3D29D0] text-white font-bold text-base py-2 px-5 rounded-3xl"
        >
          Connect Wallet
        </button>
      )}
    </nav>
  );
};

export default Navbar;
