import { JsonRpcProvider } from '@ethersproject/providers';
import { useEthersProvider } from './useEthersProvider';
import { useEthersSigner } from './useEthersSigner';

const useWeb3Provider = () => {
  const provider = useEthersProvider();
  const signer = useEthersSigner();

  const loadDefaultProvider = (chainId: number) => {
    // Mainnets
    if (chainId === 250)
      return new JsonRpcProvider('https://fantom-rpc.publicnode.com');
    if (chainId === 137)
      return new JsonRpcProvider('https://polygon-bor-rpc.publicnode.com');
    if (chainId === 56) return new JsonRpcProvider('https://rpc.ankr.com/bsc');
    if (chainId === 42161)
      return new JsonRpcProvider('https://rpc.ankr.com/arbitrum');
    if (chainId === 10)
      return new JsonRpcProvider('https://rpc.ankr.com/optimism');
    if (chainId === 43114)
      return new JsonRpcProvider('https://rpc.ankr.com/avalanche');
    if (chainId === 8453)
      return new JsonRpcProvider('https://base-rpc.publicnode.com');
    if (chainId === 81457) return new JsonRpcProvider('https://rpc.blast.io');

    // Testnets
    if (chainId === 4002)
      return new JsonRpcProvider('https://rpc.testnet.fantom.network/');
    if (chainId === 80002)
      return new JsonRpcProvider('https://rpc-amoy.polygon.technology/');
    if (chainId === 97)
      return new JsonRpcProvider('https://rpc.ankr.com/bsc_testnet_chapel');
    if (chainId === 421611)
      return new JsonRpcProvider('https://rinkeby.arbitrum.io/rpc');
    if (chainId === 420)
      return new JsonRpcProvider('https://goerli.optimism.io');
    if (chainId === 43113)
      return new JsonRpcProvider('https://api.avax-test.network/ext/bc/C/rpc');
    if (chainId === 11155111)
      return new JsonRpcProvider('https://rpc.ankr.com/eth_sepolia');
    if (chainId === 3441005)
      return new JsonRpcProvider(
        'https://pacific-rpc.testnet.manta.network/http'
      );
    if (chainId === 168587773)
      return new JsonRpcProvider('https://sepolia.blast.io');
    if (chainId === 919)
      return new JsonRpcProvider('https://sepolia.mode.network');
    if (chainId === 34443) {
      return new JsonRpcProvider('https://1rpc.io/mode');
    }
    if (chainId === 64165) {
      return new JsonRpcProvider('https://rpc.sonic.fantom.network');
    }
    if (chainId === 84532) {
      return new JsonRpcProvider('https://base-sepolia-rpc.publicnode.com');
    }
  };

  return { provider, signer, loadDefaultProvider };
};

export default useWeb3Provider;
