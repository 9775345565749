import { FC, useState, useCallback } from 'react';
import {
  usePublicClient,
  useWalletClient,
  useSwitchNetwork,
  useNetwork,
  useAccount,
} from 'wagmi';
import { parseUnits } from 'viem';

import Cross from '../../assets/img/cross.svg';
import { generateRandomHex } from '../../utils';
import interchainTokenServiceABI from '../../abis/interchainTokenServiceABI.json';
import interchainTokenFactoryABI from '../../abis/interchainTokenFactoryABI.json';

interface Props {
  project: any;
  onClose: () => void;
  onDone: (_deployedTokenAddress: string, _deployedTokenSymbol: string) => void;
}

const interchainTokenServiceContractAddress =
  '0xB5FB4BE02232B1bBA4dC8f81dc24C26980dE9e3C';
const interchainTokenFactoryContractAddress =
  '0x83a93500d23Fbc3e82B410aD07A6a9F7A0670D66';

const TokenCreationModal: FC<Props> = ({ project, onClose, onDone }) => {
  const { switchNetworkAsync } = useSwitchNetwork();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { address } = useAccount();
  const { chain: connectedChain } = useNetwork();

  const [tokenName, setTokenName] = useState<string>('');
  const [tokenSymbol, setTokenSymbol] = useState<string>('');
  const [tokenDecimals, setTokenDecimals] = useState<number>(18);
  const [totalSupply, setTotalSupply] = useState<number>(1000000000);
  const [saltHash] = useState<string>('0x' + generateRandomHex(32));

  const [isDeployingToken, setIsDeployingToken] = useState<boolean>(false);

  const switchNetworkRequired = connectedChain?.id !== Number(project.chain);

  const handleNetworkSwitch = useCallback(async () => {
    try {
      if (switchNetworkRequired && switchNetworkAsync) {
        await switchNetworkAsync(Number(project.chain));
      }
    } catch (error) {
      console.warn('Error while switching network', error);
    }
  }, [switchNetworkAsync, switchNetworkRequired, project.chain]);

  const handlTokenCreation = useCallback(async () => {
    setIsDeployingToken(true);
    try {
      if (tokenName === '') throw new Error('Token name is required');
      if (tokenSymbol === '') throw new Error('Token symbol is required');
      if (tokenDecimals === 0) throw new Error('Token decimals is required');
      if (totalSupply === 0) throw new Error('Total supply is required');
      if (saltHash === '') throw new Error('Salt hash is required');

      if (!publicClient || !walletClient) {
        throw new Error('Public client or wallet client not found');
      }

      if (!address) throw new Error('Wallet address not found');

      // @ts-ignore
      const { request, result: tokenId } = await publicClient.simulateContract({
        account: address,
        address: interchainTokenFactoryContractAddress,
        abi: interchainTokenFactoryABI,
        functionName: 'deployInterchainToken',
        args: [
          saltHash,
          tokenName,
          tokenSymbol,
          tokenDecimals,
          parseUnits(totalSupply.toString(), tokenDecimals),
          address,
        ],
      });
      // @ts-ignore
      const tokenAddress = await publicClient.readContract({
        address: interchainTokenServiceContractAddress as `0x${string}`,
        abi: interchainTokenServiceABI,
        functionName: 'interchainTokenAddress',
        args: [tokenId],
      });
      console.log('Token Address: ', tokenAddress);

      // @ts-ignore
      const txHash = await walletClient.writeContract(request);
      console.log('Token Creation Tx: ', txHash);
      // @ts-ignore
      const tx = await publicClient.waitForTransactionReceipt({
        hash: txHash,
      });
      console.log('Token Creation Tx mined', tx);

      setIsDeployingToken(false);
      localStorage.setItem(
        `${project.name}-${tokenSymbol}`,
        tokenAddress as string
      );
      onDone(tokenAddress as string, tokenSymbol);
    } catch (error) {
      console.error('Error while creating token', error);
    }
    setIsDeployingToken(false);
  }, [
    tokenName,
    tokenSymbol,
    tokenDecimals,
    totalSupply,
    saltHash,
    publicClient,
    walletClient,
    address,
    onDone,
    project.name,
  ]);

  return (
    <div className="absolute z-[51] flex items-center w-full justify-center">
      <section className="w-full sm:w-96 flex flex-col items-center gap-7 p-5 text-white bg-black rounded-3xl border-2 border-indigo-700 border-solid z-10">
        <div className="flex flex-row justify-between w-full items-center">
          <h5 className="text-xl font-semibold text-violet-400">
            Create Token
          </h5>
          <img
            src={Cross}
            alt="cross"
            onClick={() => onClose()}
            className="cursor-pointer"
          />
        </div>
        <section className="w-full flex flex-col gap-5">
          <div>
            <h5 className="text-neutral-400 text-sm font-normal">Token Name</h5>
            <input
              type="text"
              placeholder="Enter your token name"
              value={tokenName}
              onChange={(e) => {
                setTokenName(e.target.value);
              }}
              className=" max-h-[35px] w-full h-full mt-2 px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-300 text-sm font-normal focus:border-0 focus:ring-0 p-0 m-0"
            />
          </div>
          <div>
            <h5 className="text-neutral-400 text-sm font-normal">
              Token Symbol
            </h5>
            <input
              type="text"
              placeholder="Enter your token symbol"
              value={tokenSymbol}
              onChange={(e) => {
                setTokenSymbol(e.target.value);
              }}
              className=" max-h-[35px] w-full h-full mt-2 px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-300 text-sm font-normal focus:border-0 focus:ring-0 p-0 m-0"
            />
          </div>
          {/*
          <div>
            <h5 className="text-neutral-400 text-sm font-normal">
              Token Decimals
            </h5>
            <input
              type="number"
              inputMode="decimal"
              placeholder="Enter your token decimals"
              value={tokenDecimals ? tokenDecimals.toString() : ''}
              onChange={(e) => {
                setTokenDecimals(parseInt(e.target.value));
              }}
              className=" max-h-[35px] w-full h-full mt-2 px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-300 text-sm font-normal focus:border-0 focus:ring-0 p-0 m-0"
            />
          </div>
          */}
          <div>
            <h5 className="text-neutral-400 text-sm font-normal">
              Enter total supply
            </h5>
            <input
              type="number"
              inputMode="decimal"
              placeholder="Enter your total supply"
              value={totalSupply ? totalSupply.toString() : ''}
              onChange={(e) => {
                setTotalSupply(parseInt(e.target.value));
              }}
              className=" max-h-[35px] w-full h-full mt-2 px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-300 text-sm font-normal focus:border-0 focus:ring-0 p-0 m-0"
            />
          </div>
          {/*
          <div>
            <h5 className="text-neutral-400 text-sm font-normal">Salt</h5>
            <input
              type="text"
              value={saltHash}
              className=" max-h-[35px] w-full h-full mt-2 px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-300 text-sm font-normal focus:border-0 focus:ring-0 p-0 m-0"
              readOnly
            />
          </div>
          */}
        </section>
        <button
          className={`w-full ${isDeployingToken && 'cursor-wait'} px-[18px] py-2.5 bg-purple-500 font-semibold text-black rounded-[20px] justify-center items-center gap-1.5 flex`}
          onClick={() => {
            if (switchNetworkRequired) {
              handleNetworkSwitch();
              return;
            }
            handlTokenCreation();
          }}
          disabled={isDeployingToken}
        >
          {switchNetworkRequired
            ? 'Switch Network'
            : isDeployingToken
              ? 'Deploying...'
              : 'Deploy'}
        </button>
      </section>
    </div>
  );
};

export default TokenCreationModal;
