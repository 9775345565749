import React from 'react';
import { Link } from 'react-router-dom';
import fs from '../../icons/fs.svg';
import Slowmist from '../../assets/img/slowmist.svg';
import Arrow from '../../assets/img/Arrow.svg';

const Footer = () => {
  return (
    <footer className="pt-[48px] sm:pt-[64px] pb-12 flex bg-black">
      <div className="Footer w-full lg:px-[100px] px-[30px] pt-[60px] lg:pb-[70px] pb-0 flex flex-wrap gap-[186px] lg:gap-[250px] justify-between items-start flex-col lg:flex-row">
        <div className="Group39 relative">
          <div className="Frame39 w-[251px] h-[140px] left-0 lg:top-0 top-[120px] absolute flex-col justify-start items-start gap-[15px] inline-flex">
            <div className="Logo w-[75px] h-[35px] justify-center items-center gap-[3.77px] inline-flex">
              <img
                className="w-[75px] h-[35px] sm:cursor-pointer"
                src={fs}
                alt="fs_logo"
              />
            </div>
            <div className="lg:Slowmist1 lg:w-[149px] lg:h-[53px] lg:left-[1px] lg:top-[191px] lg:absolute lg:flex-col lg:justify-start lg:items-start lg:inline-flex hidden">
              <div className="MaskGroup w-[149px] h-[29.10px] relative">
                <div className="Group w-[148.98px] h-[29.10px] left-[0.05px] top-[0.03px] absolute">
                  <img
                    className="w-[149px] h-[53px] sm:cursor-pointer"
                    src={Slowmist}
                    alt="slowmist_logo"
                  />
                </div>
              </div>
            </div>
            <div className="TermsOfServiceAndPrivacyPolicy lg:text-neutral-300 lg:text-sm lg:font-normal lg:inline-flex hidden">
              © 2021-2024 FutureStarter.xyz. By using this service you agree to
              the
            </div>
            <div className="Frame39 lg:justify-start lg:items-start lg:gap-[25px] lg:flex hidden">
              {/* <Link
                to="https://fastsw.app/privacy"
                className="Privacy text-neutral-200 text-xl font-normal leading-normal flex-shrink-0"
              >
                Privacy{''}
              </Link> */}
              <Link
                to="https://app.fantomstarter.io/terms"
                className="TermsConditions text-neutral-200 text-xl font-normal leading-normal flex-shrink-0"
              >
                Terms & Conditions
              </Link>
            </div>
          </div>
        </div>

        <div className="Group-40 grid gap-[120px] grid-cols-2 lg:grid-cols-3">
          <div className="Frame39 flex-col justify-start items-start gap-2.5 flex">
            <div className="Products text-white text-xl font-semibold leading-10">
              Products
            </div>
            <Link
              to="/project"
              className="Swap text-neutral-200 text-l font-normal leading-normal"
            >
              Sell ERC-20 Tokens
            </Link>
            <Link
              to="/project"
              className="Projects text-neutral-200 text-l font-normal leading-normal"
            >
              Sell ERC-1155 NFTs
            </Link>
            <Link
              to="/project"
              className="FsmartClub text-neutral-200 text-l font-normal leading-normal"
            >
              Sell ERC-721 NFTs
            </Link>
            <Link
              to="/project"
              className="Shop text-neutral-200 text-l font-normal leading-normal"
            >
              Sell ERC-404 Tokens
            </Link>
          </div>
          <div className="Frame39 flex-col justify-start items-start gap-2.5 flex relative lg:left-0 left-[-30px]">
            <div className="Company text-white text-xl font-semibold leading-10">
              Company
            </div>
            <div className="Frame39 flex-col justify-start items-start gap-2.5 flex">
              <Link
                to="https://docs.futurestarter.xyz/"
                className="Faq text-neutral-200 text-l font-normal"
              >
                Documentation
              </Link>
              <Link
                to="mailto:hello@futurestarter.xyz"
                className="Contact text-neutral-200 text-l font-normal"
              >
                Contact
              </Link>
              <Link
                to="https://angel.co/company/fsmultichain/"
                className="Careers text-neutral-200 text-l font-normal"
              >
                Careers
              </Link>
            </div>
          </div>
          <div className="Frame39 flex-col justify-start items-start gap-2.5 flex relative lg:top-0 top-[-100px]">
            <div className="FollowUs text-white text-xl font-semibold leading-10 flex-shrink-0">
              Follow us
            </div>
            <div className="Frame39 flex-col justify-start items-start gap-2.5 flex">
              <Link
                to="https://blog.futurestarter.xyz/"
                className="Frame39 justify-center items-center gap-px inline-flex"
              >
                <div className="Twitter text-neutral-200 text-l font-normal">
                  Blog
                </div>
                <div className="ArrowUpRight w-6 h-6 relative">
                  <img
                    src={Arrow}
                    className="w-6 h-6 relative"
                    alt="arrow_img"
                  />
                </div>
              </Link>
              {/* <Link
                to="/"
                className="Frame41 justify-center items-center gap-px inline-flex"
              >
                <div className="Discord text-neutral-200 text-l font-normal">
                  Discord
                </div>
                <div className="ArrowUpRight w-6 h-6 relative">
                  <img
                    src={Arrow}
                    className="w-6 h-6 relative"
                    alt="arrow_img"
                  />
                </div>
              </Link>
              <Link
                to="/"
                className="Frame40 justify-center items-center gap-px inline-flex"
              >
                <div className="Telegram text-neutral-200 text-l font-normal">
                  Telegram
                </div>
                <div className="ArrowUpRight w-6 h-6 relative">
                  <img
                    src={Arrow}
                    className="w-6 h-6 relative"
                    alt="arrow_img"
                  />
                </div>
              </Link> */}
            </div>
          </div>
        </div>

        <div className="Group41 relative lg:hidden top-[-200px] lg:top-0">
          <div className="Frame41 w-[251px] h-[140px] absolute flex-col justify-start items-start gap-[15px] inline-flex">
            <p className="TermsOfServiceAndPrivacyPolicy text-neutral-300 text-sm font-normal inline-flex">
              © 2021-2023 Futurestarter.xyz. By using this service you agree to
              the
            </p>
            <div className="Frame41 justify-start items-start gap-[25px] flex">
              <Link
                to="https://app.fantomstarter.io/terms"
                className="TermsConditions text-neutral-200 text-xl font-normal leading-normal flex-shrink-0"
              >
                Terms and Conditions
              </Link>
            </div>
            <div className="Slowmist1 w-[149px] h-[53px] absolute flex-col justify-start items-start inline-flex">
              <div className="MaskGroup w-[149px] h-[29.10px] relative">
                <div className="Group w-[148.98px] h-[29.10px] top-[130px] absolute">
                  <img
                    className="w-[149px] h-[53px]"
                    src={Slowmist}
                    alt="slowmist_logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
