import styled from 'styled-components';
import theme from '../theme';

export type MediaSizes = 'xs' | 'sm' | 'md' | 'lg';
export const mediaExact = {
  xs: (styles: any) => `
  @media only screen and (min-width: 240px) and (max-width: 480px) {
    ${styles}
  }
  `,
  sm: (styles: any) => `
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    ${styles}
  }
  `,
  md: (styles: any) => `
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    ${styles}
  }
  `,
  lg: (styles: any) => `
  @media only screen and (min-width: 1025px) {
    ${styles}
  }
  `,
} as any;
export const mediaTill = {
  xs: (styles: any) => `
  @media only screen and (max-width: 480px) { 
    ${styles}
  }
  `,
  sm: (styles: any) => `
  @media only screen and (max-width: 768px) {
    ${styles}
  }
  `,
  md: (styles: any) => `
  @media only screen and (max-width: 1024px) {
    ${styles}
  }
  `,
  lg: (styles: any) => `
  @media only screen and (min-width: 1025px) {
    ${styles}
  }
  `,
} as any;
export const mediaFrom = {
  xs: (styles: any) => `
  @media only screen and (min-width: 240px) {
    ${styles}
  }
  `,
  sm: (styles: any) => `
  @media only screen and (min-width: 481px) {
    ${styles}
  }
  `,
  md: (styles: any) => `
  @media only screen and (min-width: 769px) {
    ${styles}
  }
  `,
  lg: (styles: any) => `
  @media only screen and (min-width: 1025px) {
    ${styles}
  }
  `,
} as any;

export const Body = styled.div`
  background-color: white;
  color: ${theme.colors.font['1']};
  display: flex;
  font-size: calc(10px + 2vmin);
  min-height: calc(100vh);
  display: flex;
  flex-direction: row;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const Heading1 = styled.div`
  font-size: 30px;
  font-weight: bold;
`;

export const Heading2 = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

export const Heading3 = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export const Heading4 = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const Typo1 = styled.div`
  font-size: 18px;
`;

export const Typo2 = styled.div`
  font-size: 16px;
`;

export const Typo3 = styled.div`
  font-size: 14px;
`;

export const Button = styled.button<{
  variant?: 'primary' | 'secondary';
  disabled?: boolean;
}>`
  width: 100%;
  background: ${theme.colors.primary[3]};
  border: none;
  border-radius: 12px;
  color: white;
  cursor: ${(props) => (!props.disabled ? 'pointer' : 'not-allowed')};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  padding: 10px 24px;
  transition: 0.2s all;

  ${(props) => props.disabled && 'opacity: 0.6'};
  :active {
    transform: scale(0.98);
  }
`;

export const OverlayButton = styled.button<{ disabled?: boolean }>`
  background-color: transparent;
  border: none;
  text-decoration: none;
  cursor: ${(props) => !props.disabled && 'pointer'};
  color: inherit;
  transition: 0.2s all;

  ${(props) => props.disabled && 'opacity: 0.6'};
  :active {
    transform: scale(0.98);
  }
`;

export const Container = styled.div<any>`
  padding: 24px;
  background-color: ${theme.colors.primary[2]};
`;

export const Input = styled.input<{ disabled?: boolean }>`
  flex: 1;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: normal;
  opacity: ${(props) => props.disabled && 0.6};

  :focus {
    outline: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-input-placeholder {
    font-size: 14px;
    line-height: 3;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }
`;
