import { FC } from 'react';

import Cross from '../../assets/img/cross.svg';
import Check from '../../assets/img/check.svg';

interface Props {
  onClose: () => void;
  onDone: () => void;
}

const SuccessfulModal: FC<Props> = ({ onClose, onDone }) => {
  return (
    <div className="absolute z-[51] flex items-center w-full justify-center">
      <section className="w-full sm:w-80 flex flex-col items-center gap-7 p-5 text-white bg-black rounded-3xl border-2 border-indigo-700 border-solid z-10">
        <div className="flex flex-row justify-between w-full items-center">
          <h5 className="text-xl font-semibold text-violet-400">Complete</h5>
          <img
            src={Cross}
            alt="cross"
            onClick={() => onClose()}
            className="cursor-pointer"
          />
        </div>
        <img src={Check} alt="check" />
        <h3 className="text-2xl text-violet-400 text-center">
          Deployment successful.
          <p className="self-stretch mt-2.5 text-center text-neutral-400 text-sm">
            We have successfully deployed you IDO sale contract!
          </p>
        </h3>
        <button
          className="w-full px-[18px] py-2.5 bg-purple-500 font-semibold text-black rounded-[20px] justify-center items-center gap-1.5 flex"
          onClick={() => onDone()}
        >
          Done
        </button>
      </section>
    </div>
  );
};

export default SuccessfulModal;
