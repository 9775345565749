import { FC, useEffect, useState } from 'react';

import Cross from '../../assets/img/cross.svg';
import { AccountLinkForm } from '../Form';

import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';

interface Props {
  onClose: () => void;
  onDone: (_deployedTokenAddress: string, _deployedTokenSymbol: string) => void;
}

const AccountLinkingModal: FC<Props> = ({ onClose, onDone }) => {
  const {
    circle: { getWireBanks, getWireBankDescription },
  } = useFantomStarterAdminApi();

  const [userWireIds, setUserWireIds] = useState<string[]>([]);
  const [wireBankDescriptions, setWireBankDescriptions] = useState<string[]>(
    []
  );

  useEffect(() => {
    const fetchWireBanks = async () => {
      const result = await getWireBanks();
      if (result.status === 200) {
        setUserWireIds(result.data ? result.data : []);
      }
    };

    fetchWireBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchWireBankDescriptions = async () => {
      const descriptions = await Promise.all(
        userWireIds.map(async (wireId) => {
          const result = await getWireBankDescription(wireId);
          console.log(result);
          return result.data;
        })
      );

      setWireBankDescriptions(descriptions);
    };

    fetchWireBankDescriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userWireIds]);

  console.log(wireBankDescriptions);

  return (
    <div className="absolute z-[51] flex items-center w-full justify-center">
      <section className="w-full sm:w-[400px] flex flex-col items-center gap-7 p-5 text-white bg-black rounded-3xl border-2 border-indigo-700 border-solid z-10">
        <div className="flex flex-row justify-between w-full items-center">
          <h5 className="text-xl font-semibold text-violet-400">
            {`${userWireIds.length > 0 ? 'Manage' : 'Add'} wire bank account`}
          </h5>
          <img
            src={Cross}
            alt="cross"
            onClick={() => onClose()}
            className="cursor-pointer"
          />
        </div>
        <section className="w-full flex flex-col gap-5">
          {userWireIds.length === 0 ? (
            <AccountLinkForm
              onBackClick={() => console.log('Back')}
              styles={{
                fontFamily: undefined,
                borderRadius: '20px',
                color: undefined,
                buttonColor: '#A855F7',
                secondaryColor: undefined,
              }}
            />
          ) : (
            wireBankDescriptions.map((description, index) => (
              <section key={index}>
                <h5 className="text-lg font-semibold text-violet-400">
                  {description}
                </h5>
              </section>
            ))
          )}
        </section>
      </section>
    </div>
  );
};

export default AccountLinkingModal;
