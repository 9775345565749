import { useEffect, useState } from 'react';
import { Contract } from '@ethersproject/contracts';

import fsSaleV4 from '../abis/fsSaleV4.json';
import fsSaleV4Ino from '../abis/fsSaleV4Ino.json';
import type {
  FallbackProvider,
  JsonRpcProvider,
  JsonRpcSigner,
} from '@ethersproject/providers';

const useFantomStarterSale = ({
  chainId,
  saleAddress,
  provider,
  signer,
  isINO,
}: {
  chainId: number | undefined;
  saleAddress: string | undefined;
  provider: FallbackProvider | JsonRpcProvider;
  signer: JsonRpcSigner | undefined;
  isINO: boolean;
}) => {
  const [saleContract, setSaleContract] = useState<Contract | null>(null);
  const [networkChangeRequired, setNetworkChangeRequired] = useState(false);
  const [refetchCount, setRefetchCount] = useState(0);

  const refetchSale = () => {
    setRefetchCount(refetchCount + 1);
  };

  useEffect(() => {
    if (provider && chainId) {
      provider.getNetwork().then((network) => {
        if (network.chainId === chainId) {
          setNetworkChangeRequired(false);
        } else {
          setNetworkChangeRequired(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, refetchCount]);

  useEffect(() => {
    if (signer && saleAddress) {
      const abi = isINO ? fsSaleV4Ino : fsSaleV4;
      const saleContractInstance = new Contract(saleAddress, abi, signer);
      setSaleContract(saleContractInstance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signer, refetchCount]);

  return { saleContract, networkChangeRequired, refetchSale };
};

export default useFantomStarterSale;
