import { useState, useCallback } from 'react';
import { TokenCreationModal } from '../Modal';

interface Step4Props {
  response: any;
  setResponse: (response: any) => void;
  onNextClick: () => void;
  onPrevlick?: () => void;
}

const Step4: React.FC<Step4Props> = ({
  response,
  setResponse,
  onNextClick,
  onPrevlick,
}) => {
  const [inputVal, setInputVal] = useState<any>({
    name: response.name,
    description: response.description,
    tags: '',
    chain: response.chain,
    logo_image_url: response.logo_image_url,
    main_image_url: response.main_image_url,
    start_date_whitelist: response.start_date_whitelist
      ? response.start_date_whitelist
      : null,
    end_date_whitelist: response.end_date_whitelist
      ? response.end_date_whitelist
      : null,
    start_date_funding: response.start_date_funding
      ? response.start_date_funding
      : null,
    end_date_funding: response.end_date_funding
      ? response.end_date_funding
      : null,
    ido_token_symbol: response.ido_token_symbol
      ? response.ido_token_symbol
      : '',
    pay_token_symbol: response.pay_token_symbol
      ? response.pay_token_symbol
      : 'axlUDSC',
    ratio: null,
    sale_version: response.sale_version,
    with_whitelist: response.with_whitelist,
    with_lottery: response.with_lottery,
    only_staked: response.only_staked,
    with_kyc: response.with_kyc,
  });
  console.log('response', response);
  const [contractProvidesLiquidity, setContractProvidesLiquidity] = useState(
    response.sale_version === 4 || response.sale_version === 6 ? false : true
  );
  const [currentSaleVersion, setCurrentSaleVersion] = useState(
    response.sale_version
  );
  const [toggleTokenCreationModal, setToggleTokenCreationModal] =
    useState(false);

  const handleIdoTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputVal((prevInputVal: any) => ({
      ...prevInputVal,
      ido_token_symbol: e.target.value,
    }));
  };

  const handlePayTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputVal((prevInputVal: any) => ({
      ...prevInputVal,
      pay_token_symbol: e.target.value,
    }));
  };

  const handleNextClick = () => {
    setResponse(inputVal);
    onNextClick();
  };

  const handleSubmit = () => {
    setResponse(inputVal);
  };

  const handlePrevClick = () => {
    setResponse(inputVal);
    if (typeof onPrevlick === 'function') {
      onPrevlick();
    }
  };

  const handleToggleContractProvidesLiquidity = useCallback(
    (e: any) => {
      e.preventDefault();
      if (currentSaleVersion !== 8 || currentSaleVersion !== 10) {
        setInputVal((prevInputVal: any) => ({
          ...prevInputVal,
          sale_version: inputVal.sale_version + 4,
        }));
        setCurrentSaleVersion(inputVal.sale_version + 4);
      }
      if (currentSaleVersion === 8 || currentSaleVersion === 10) {
        setInputVal((prevInputVal: any) => ({
          ...prevInputVal,
          sale_version: inputVal.sale_version - 4,
        }));
        setCurrentSaleVersion(inputVal.sale_version - 4);
      }
      setContractProvidesLiquidity(!contractProvidesLiquidity);
    },
    [
      contractProvidesLiquidity,
      inputVal.sale_version,
      setInputVal,
      setContractProvidesLiquidity,
      setCurrentSaleVersion,
      currentSaleVersion,
    ]
  );

  return (
    <div className="overflow-x-hidden overflow-y-hidden sm:overflow-visible w-full sm:w-fit px-5 sm:px-0">
      {toggleTokenCreationModal && (
        <div className="relative">
          <TokenCreationModal
            project={{
              name: inputVal.name,
              chain: inputVal.chain,
            }}
            onClose={() => {
              setToggleTokenCreationModal(false);
            }}
            onDone={(_deployedTokenAddress, _deployedTokenSymbol) => {
              setInputVal((prevInputVal: any) => ({
                ...prevInputVal,
                ido_token_symbol: _deployedTokenSymbol,
              }));
              setToggleTokenCreationModal(false);
            }}
          />
        </div>
      )}
      <div
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.03)',
          borderColor: 'rgba(255, 255, 255, 0.03)',
        }}
        className="mt-[50px] h-fit  w-full  p-[20px] rounded-[30px] border-[2px]   sm:w-[490px] sm:h-fit   sm:p-[45px] sm:rounded-[30px] sm:border-[2px] flex-col justify-start items-start gap-[30px] inline-flex"
      >
        <div className=" justify-start items-start inline-flex ">
          <p className="text-[30px] leading-[64px]  font-[700]  text-transparent bg-clip-text bg-gradient-to-r from-[#987EF5] to-[#F19571] opacity-100 sm:text-[40px] sm:leading-[64px]  sm:font-[700]  sm:text-transparent sm:bg-clip-text sm:bg-gradient-to-r sm:from-[#987EF5] sm:to-[#F19571] sm:opacity-100">
            Tokens information
          </p>
        </div>

        <div className=" flex-col justify-start items-start gap-2.5 flex w-full">
          <p className="text-[20px] text-[#A68DFF] font-[600] leading-[23.87px] sm:text-[20px] sm:text-[#A68DFF] sm:font-[600]  sm:leading-[23.87px]">
            Sold Token Symbol
          </p>

          <input
            className="rounded-[15px] p-[20px] w-full text-white bg-[#000000] opacity-30 border-[1px] border-opacity-60  inset-0 z-10 placeholder:text-white  sm:rounded-[15px] sm:p-[20px] sm:w-[400px] sm:text-white sm:bg-[#000000] sm:opacity-30 sm:border-[1px] sm:border-opacity-60  sm:inset-0 sm:z-10 sm:placeholder:text-white"
            type="text"
            placeholder="eg. WETH"
            value={inputVal.ido_token_symbol}
            onChange={handleIdoTokenChange}
          />
        </div>

        <section
          onClick={() =>
            // window.open('https://interchain.axelar.dev/', '_blank')
            setToggleTokenCreationModal(true)
          }
          className="-mt-5 w-full flex gap-2.5 items-start py-2.5 px-5 text-white bg-black rounded-3xl border-2 border-indigo-700 border-solid z-10 cursor-pointer"
        >
          <div className="flex flex-col grow shrink-0 basis-0">
            <h3 className="text-xs font-medium">No token? No problem!</h3>
            <p className="text-xs">Mint an Interchain token with Axelar.</p>
          </div>
        </section>

        <div className="flex-col justify-start items-start gap-2.5 flex w-full">
          <p className="text-[20px] text-[#A68DFF] font-[600] leading-[23.87px] sm:text-[20px] sm:text-[#A68DFF] sm:font-[600]  sm:leading-[23.87px] ">
            Payment Token Symbol
          </p>
          <input
            className="rounded-[15px] p-[20px] w-full text-white bg-[#000000] opacity-30 border-[1px] border-opacity-60  inset-0 z-10 placeholder:text-white   sm:rounded-[15px] sm:p-[20px] sm:w-[400px] sm:text-white sm:bg-[#000000] sm:opacity-30 sm:border-[1px] sm:border-opacity-60  sm:inset-0 sm:z-10 sm:placeholder:text-white"
            type="text"
            placeholder="eg. USDC"
            value={inputVal.pay_token_symbol}
            onChange={handlePayTokenChange}
          />
        </div>

        {inputVal.sale_version === 3 || inputVal.sale_version === 5 ? null : (
          <div className="Card w-full p-3.5 rounded-[10px] border border-neutral-700 flex-row justify-between items-start inline-flex">
            <div className="Frame38 justify-start items-center gap-[5px] inline-flex">
              <div className="Label text-neutral-400 text-sm font-normal">
                Add pair to liquidity pool
              </div>
            </div>
            <div className="relative Frame5724 self-stretch justify-between items-start gap-2.5 inline-flex">
              <div className="Label text-white text-sm font-normal">
                {contractProvidesLiquidity ? 'Yes' : 'No'}
              </div>
              <div
                className="relative inline-block"
                onClick={(e) => handleToggleContractProvidesLiquidity(e)}
              >
                <div
                  className={`peer pl-[22.35px] pr-[1.35px] py-[1.35px] w-[42px] h-[21px] cursor-pointer appearance-none rounded-full  ${
                    contractProvidesLiquidity
                      ? 'bg-purple-500'
                      : 'bg-neutral-700'
                  }  `}
                ></div>
                <span
                  className={`pointer-events-none absolute  top-[1.5px] block w-[18.29px] h-[18.29px] rounded-full bg-white transition-all duration-200 ${
                    contractProvidesLiquidity
                      ? 'transform translate-x-[22px]'
                      : 'transform translate-x-[0px]'
                  }`}
                />
              </div>
            </div>
          </div>
        )}

        <div className="w-full flex flex-row justify-between gap-5">
          <div className="Card h-[45px] px-6 py-2 rounded-[60px] border border-violet-400 justify-center items-center gap-2 inline-flex flex-shrink-0">
            <button
              onClick={handlePrevClick}
              className="Back border-violet-400 text-violet-400 text-base font-semibold flex-shrink-0"
            >
              ← Back
            </button>
          </div>
          <button
            disabled={!inputVal.ido_token_symbol || !inputVal.pay_token_symbol}
            className={`Button relative sm:left-0 w-[200px] sm:w-[280px] h-[45px] px-8 py-[13px] ${!inputVal.ido_token_symbol || !inputVal.pay_token_symbol ? 'opacity-50' : 'opacity-100'}  bg-gradient-to-r from-[#C25FFF] to-[#3D29D0]  drop-shadow-[8_-2px_4px_rgba(0, 0, 0, 0.25) z-10  rounded-[20px] shadow justify-center items-center gap-2 flex `}
            onClick={() => {
              handleNextClick();
              handleSubmit();
            }}
          >
            <div className="Next text-white text-base font-semibold">Next</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step4;
