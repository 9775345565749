import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import * as React from 'react';
import { useRef, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import IMG from './../../../assets/img/ic_image.svg';
import PHOTO from './../../../assets/img/ic_link.svg';

import type { InsertImagePayload } from './ImagesPlugin';
import { INSERT_IMAGE_COMMAND } from './ImagesPlugin';

export function FillURL() {
  const srcfile = prompt('Enter the URL of the image:', '');

  if (srcfile !== null) {
    return srcfile;
  } else {
    // Handle the case where the user cancels the prompt or enters nothing
    return '';
  }
}

const resizeFile = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      'PNG',
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      'file'
    );
  });

export default function ToolbarPlugin(): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const onClick = (payload: InsertImagePayload) => {
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
  };

  const [show, setShow] = useState<boolean>(false);
  const [imageUrl1, setImageUrl1] = useState<string>('');
  const [imgFile, setImgFile] = useState<File | null>(null);
  const [imgURL, setImgURL] = useState<string>('');

  const uploadPreset =
    process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET ?? 'default_upload_preset';
  const cloudName =
    process.env.REACT_APP_CLOUDINARY_CLOUD_NAME ?? 'default_cloud_name';

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files?.[0];

    const image = (await resizeFile(file)) as any;

    if (image) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result as string;
        onClick({
          altText: 'Uploaded Image',
          src: result,
        });
      };
      reader.readAsDataURL(image);
    }
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];

    try {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const result = e.target?.result as string;
          setImgFile(file);
          setImageUrl1(result);

          setShow(true);
        };

        reader.readAsDataURL(file);
      }
    } catch (err) {
      console.log(err);
      setImgFile(null);
      setShow(false);
    }
  };

  let url = '';

  const uploadImage = async () => {
    if (imgFile) {
      const data = new FormData();
      data.append('file', imgFile);
      data.append('upload_preset', uploadPreset);
      data.append('cloud_name', cloudName);
      data.append('folder', 'Cloudinary-React');

      try {
        const response = await fetch(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
          {
            method: 'POST',
            body: data,
          }
        );
        const res = await response.json();
        const Iurl = res.secure_url;

        setImgURL(url);

        onClick({
          altText: 'Uploaded Image',
          src: Iurl,
        });

        console.log('URL', Iurl);
      } catch (error) {
        console.log('ERROR');
      }
    }
  };

  // trigger the upload whenever the image change

  React.useEffect(() => {
    uploadImage();
  }, [imgFile]);

  const handleClickUpload = async () => {
    // Trigger the file input element after the image is clicked

    fileInputRef.current?.click();
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />

      <button
        onClick={handleClickUpload}
        className="MenuBarButton justify-start items-start flex"
      >
        <div className="MenuBarButtonBase w-7 px-1 rounded justify-center items-center gap-0.5 flex">
          <div className="IcImage w-5 h-5 relative">
            <img src={IMG} className="IcImage w-5 h-5 relative" alt={IMG} />
          </div>
        </div>
      </button>

      <button
        onClick={() =>
          onClick({
            altText: 'Uploaded Image',
            src: FillURL(),
          })
        }
        className="MenuBarButton justify-start items-start flex"
      >
        <div className="MenuBarButtonBase w-7 px-1 rounded justify-center items-center gap-0.5 flex">
          <div className="IcImage w-5 h-5 relative">
            <img src={PHOTO} className="IcImage w-5 h-5 relative" alt={IMG} />
          </div>
        </div>
      </button>
    </>
  );
}
