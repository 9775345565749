const useDocusignApi = () => {
  const baseUrl = 'https://raven.futurestarter.xyz/dapp/api';

  const headers = {
    'Content-Type': 'application/json',
  };

  const sendTemplateEnvelope = async (
    templateId: string,
    roleName: string,
    email: string,
    name: string
  ) => {
    const body = JSON.stringify({
      templateId,
      roleName,
      email,
      name,
    });

    return fetch(`${baseUrl}/docusign/send/template/envelope`, {
      method: 'POST',
      headers,
      body: body,
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const getTemplateEditViewUrl = async (
    templateId: string,
    returnUrl: string
  ) => {
    const body = JSON.stringify({
      templateId,
      returnUrl,
    });

    return fetch(`${baseUrl}/docusign/edit/template/view`, {
      method: 'POST',
      headers,
      body: body,
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const getTemplateName = (projectName: string) => {
    const templateNameWithoutRwa = projectName.replace('--rwa', '');
    const formattedTemplateName = templateNameWithoutRwa
      .toLowerCase()
      .replace(/\s+/g, '_');
    return formattedTemplateName;
  };

  const createTemplate = async (projectName: string, file: File) => {
    const formData = new FormData();
    const formattedTemplateName = getTemplateName(projectName);
    formData.append('templateName', formattedTemplateName);
    formData.append('file', file);

    return fetch(`${baseUrl}/docusign/create/template`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const getTemplates = async () => {
    return fetch(`${baseUrl}/docusign/templates`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const getTemplate = async (templateId: string) => {
    return fetch(`${baseUrl}/docusign/templates/${templateId}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return {
    sendTemplateEnvelope,
    getTemplateEditViewUrl,
    createTemplate,
    getTemplateName,
    getTemplates,
    getTemplate,
  };
};

export default useDocusignApi;
