import { useContext, useEffect } from 'react';
import { LoginContext } from '../context/LoginProvider';
import { decodeToken } from 'react-jwt';
import { AppContext } from '../context/AppProvider';

const useAppSettings = () => {
  const [env, setEnv] = useContext(AppContext);

  return { isProd: env === 'prod', setEnv };
};

export default useAppSettings;
