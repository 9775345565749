import { randomBytes } from '@ethersproject/random';

export function generateRandomHex(size: number = 32) {
  const bytes = randomBytes(32); // Generates 32 random bytes
  const hexString = bytes.reduce(
    (str, byte) => str + byte.toString(16).padStart(2, '0'),
    ''
  );
  return hexString;
}
