import { useEffect, useState } from 'react';
import useFantomStarterAdminApi from './useFantomStarterAdminApi';
import useToken from './useToken';
import useAppSettings from './useAppSettings';

const useAllProjects = () => {
  const { getAllProjects, getAllProjectsForOwner } = useFantomStarterAdminApi();
  const [projects, setProjects] = useState<any[]>([]);
  const { isProd } = useAppSettings();
  const { getUser } = useToken();
  const [refetchCount, setRefetchCount] = useState(0);

  const refetch = () => {
    setRefetchCount(refetchCount + 1);
  };

  useEffect(() => {
    const user: any = getUser();
    if (user.role === 'admin') {
      getAllProjects().then((response) => {
        if (response?.data?.result) {
          setProjects(response.data.result);
        }
      });
    }
    if (user.role === 'project') {
      getAllProjectsForOwner(user.id).then((response) => {
        if (response?.data?.result) {
          setProjects(response.data.result);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProd, refetchCount]);

  return { projects, refetch };
};

export default useAllProjects;
