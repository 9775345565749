import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useState } from 'react';

import { HeadingTagType } from '@lexical/rich-text';
import { FORMAT_HEADING_COMMAND } from '../CustomHeadingPlugin/CustomHeadingPlugin';

export const CustomHeadingActions = () => {
  const [editor] = useLexicalComposerContext();
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [toggleButtonLabel, setToggleButtonLabel] = useState('Normal');

  const handleOnClick = (tag: HeadingTagType) => {
    editor.dispatchCommand(FORMAT_HEADING_COMMAND, tag);
  };

  return (
    <>
      <div className="relative">
        <button
          id="dropdownDefaultButton"
          data-dropdown-toggle="dropdown"
          className="text-white bg-[#323236] focus:outline-none font-medium rounded-md text-sm px-3 py-1.5 text-center inline-flex items-center"
          type="button"
          onClick={() => setToggleDropdown(!toggleDropdown)}
        >
          {toggleButtonLabel}
          <svg
            className="w-2.5 h-2.5 ms-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div
          id="dropdown"
          className={`${!toggleDropdown && 'hidden'} absolute top-10 rounded-md shadow w-44 bg-[#323236]`}
        >
          <ul
            className="py-2 text-sm text-white font-medium cursor-pointer"
            aria-labelledby="dropdownDefaultButton"
          >
            <li
              onClick={() => {
                setToggleButtonLabel('Normal');
                handleOnClick('h6');
                setToggleDropdown(false);
              }}
            >
              <div className="block px-4 py-2 hover:bg-[#B36CFF] hover:text-black">
                Normal
              </div>
            </li>
            <li
              onClick={() => {
                setToggleButtonLabel('Heading 1');
                handleOnClick('h3');
                setToggleDropdown(false);
              }}
            >
              <div className="block px-4 py-2 hover:bg-[#B36CFF] hover:text-black">
                Heading 1
              </div>
            </li>
            <li
              onClick={() => {
                setToggleButtonLabel('Heading 2');
                handleOnClick('h4');
                setToggleDropdown(false);
              }}
            >
              <div className="block px-4 py-2 hover:bg-[#B36CFF] hover:text-black">
                Heading 2
              </div>
            </li>
            <li
              onClick={() => {
                setToggleButtonLabel('Heading 3');
                handleOnClick('h5');
                setToggleDropdown(false);
              }}
            >
              <div className="block px-4 py-2 hover:bg-[#B36CFF] hover:text-black">
                Heading 3
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CustomHeadingActions;
