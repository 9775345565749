import { EditorState } from 'lexical';
interface TextNode {
  detail: number;
  format: number;
  mode: string;
  style: string;
  text: string;
  type: string;
  version: number;
}

interface ImgNode {
  altText: string;
  caption: {
    editorState: {
      root: {
        children: any[];
        direction: string | null;
        format: string;
        indent: number;
        type: string;
        version: number;
      };
    };
  };
  height: number;
  maxWidth: number;
  showCaption: boolean;
  src: string;
  type: string;
  version: number;
  width: number;
}

interface ImageNode {
  children: ImgNode[];
  direction: string | null;
  format: string;
  indent: number;
  type: string;
  version: number;
}

interface TreeNode {
  children: (TextNode | TreeNode | ImgNode)[];
  direction: string | null;
  format: string;
  indent: number;
  type: string;
  version: number;
  tag?: string;
}

interface RootNode {
  root: TreeNode;
}

export function fsParagraphsToEditorState(paragraphs: any[]): RootNode {
  const root: TreeNode = {
    children: [],
    direction: 'ltr',
    format: '',
    indent: 0,
    type: 'root',
    version: 1,
  };

  const input: any[] = [];
  paragraphs.forEach((paragraph) => {
    input.push({ title: paragraph.title, tag: paragraph.tag, format: 0 });
    if (paragraph.text) {
      input.push({ text: paragraph.text, format: 0 });
    }
    if (paragraph.image) {
      input.push({ image: paragraph.image, format: 0 });
    }
  });

  if (!Array.isArray(input)) {
    // If input is not an array, return an empty editor
    root.children.push({
      children: [],
      direction: 'ltr',
      format: '',
      indent: 0,
      type: 'heading',
      version: 1,
    });
    return { root };
  }

  if (input.length === 0) {
    root.children.push({
      children: [],
      direction: 'ltr',
      format: '',
      indent: 0,
      type: 'heading',
      version: 1,
    });
    return { root };
  }

  input.forEach((item) => {
    if (item.flag === 'empty') {
      root.children.push({
        children: [],
        direction: 'ltr',
        format: '',
        indent: 0,
        type: 'paragraph',
        version: 1,
      });
      return { root };
    }

    if (item.title) {
      const heading: TreeNode = {
        children: [
          {
            detail: 0,
            format: item.format || 0,
            mode: 'normal',
            style: '',
            text: item.title,
            type: 'text',
            version: 1,
          },
        ],
        direction: 'ltr',
        format: '',
        indent: 0,
        type: 'heading',
        version: 1,
        tag: item.tag || 'h5',
      };
      root.children.push(heading);
    }

    if (item.text) {
      const paragraph: TreeNode = {
        children: [
          {
            detail: 0,
            format: item.format || 0,
            mode: 'normal',
            style: '',
            text: item.text,
            type: 'text',
            version: 1,
          },
        ],
        direction: 'ltr',
        format: '',
        indent: 0,
        type: 'paragraph',
        version: 1,
      };
      root.children.push(paragraph);
    }

    if (item.image) {
      const image: TreeNode = {
        children: [
          {
            altText: item.altText || '',
            caption: {
              editorState: {
                root: {
                  children: [],
                  direction: null,
                  format: '',
                  indent: 0,
                  type: 'root',
                  version: 1,
                },
              },
            },
            height: 0,
            maxWidth: 450,
            showCaption: false,
            src: item.image,
            type: 'image',
            version: 1,
            width: 0,
          },
        ],
        direction: null, // Set the correct direction if available
        format: item.format || '', // Set the correct format if available
        indent: item.indent || 0, // Set the correct indent if available
        type: 'paragraph',
        version: 1,
      };
      root.children.push(image);
    }
  });

  return { root };
}

export function editorStateToFsParagraphs(root: RootNode): any[] {
  const input: any[] = [];
  const { children }: any = root.root;

  console.log('children', children);
  for (const node of children) {
    if (node.children.length === 0) continue;

    let currentItem: any = {};
    if (node.type === 'heading') {
      if (node.children[0].type === 'image') {
        currentItem.image = node.children[0].src;
      }
      currentItem.title = node.children[0].text;
      currentItem.tag = node.tag;
      currentItem.format = node.children[0].format;
    } else if (node.type === 'paragraph') {
      if (node.children[0].type === 'image') {
        currentItem.image = node.children[0].src;
      } else if (node.children.length === 0) {
        currentItem.flag = 'empty';
      } else {
        currentItem.text = node.children[0].text;
        currentItem.format = node.children[0].format;
      }
    }
    input.push({ ...currentItem });
  }
  console.log(input);
  const paragraphs: any[] = [];
  for (let i = 0; i < input.length; i++) {
    const current = input[i];
    const next = input[i + 1];

    if (current.title) {
      if (next.text) {
        paragraphs.push({
          title: current.title,
          text: next.text,
          tag: current.tag,
        });
        i++;
      } else {
        paragraphs.push({
          title: current.title,
          tag: current.tag,
        });
      }
    }
    if (current.image) {
      paragraphs.push({
        image: current.image,
      });
    }
    if (current.text) {
      paragraphs.push({
        text: current.text,
      });
    }
  }
  console.log('paragraphs', paragraphs);
  return paragraphs;
}
