import { useContext, useEffect } from 'react';
import { LoginContext } from '../context/LoginProvider';
import { decodeToken } from 'react-jwt';

const useToken = () => {
  const [token, setToken] = useContext(LoginContext);

  const logout = () => {
    setToken(null);
    localStorage.removeItem('token');
  };

  const getUser = () => {
    return decodeToken(token);
  };

  useEffect(() => {
    if (token) {
      return localStorage.setItem('token', token);
    }
    setToken(localStorage.getItem('token'));
  }, [token]);

  return { token, setToken, getUser, logout };
};

export default useToken;
