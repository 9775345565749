import React, { useState } from 'react';
import { Footer } from '../../components/Footer';
import Step1 from '../../components/Steps/Step1';
import Navbar from '../../components/Navbar/Navbar';
import Step2 from '../../components/Steps/Step2';
import Step3 from '../../components/Steps/Step3';
import Step4 from '../../components/Steps/Step4';
import Step5 from '../../components/Steps/Step5';

export default function Project(): JSX.Element {
  const steps: number[] = [1, 2, 3, 4, 5];
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [response, setResponse] = useState<any>({
    name: '',
    description: '',
    tags: '',
    chain: 4002,
    logo_image_url: '',
    main_image_url: '',
    start_date_whitelist: null,
    end_date_whitelist: null,
    start_date_funding: null,
    end_date_funding: null,
    ido_token_symbol: '',
    pay_token_symbol: 'axlUDSC',
    ratio: null,
    sale_version: 0,
  });

  const handleButtonClick = () => {
    if (currentStep > 4) {
      let step = currentStep % 4;
      setCurrentStep(step);
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrevClick = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  let componentToRender: JSX.Element | null;

  switch (currentStep) {
    case 1:
      componentToRender = (
        <Step1
          response={response}
          setResponse={setResponse}
          onNextClick={handleButtonClick}
        />
      );
      break;
    case 2:
      componentToRender = (
        <Step2
          response={response}
          setResponse={setResponse}
          onNextClick={handleButtonClick}
          onPrevlick={handlePrevClick}
        />
      );
      break;
    case 3:
      componentToRender = (
        <Step3
          response={response}
          setResponse={setResponse}
          onNextClick={handleButtonClick}
          onPrevlick={handlePrevClick}
        />
      );
      break;
    case 4:
      componentToRender = (
        <Step4
          response={response}
          setResponse={setResponse}
          onNextClick={handleButtonClick}
          onPrevlick={handlePrevClick}
        />
      );
      break;
    case 5:
      componentToRender = (
        <Step5
          response={response}
          setResponse={setResponse}
          onNextClick={handleButtonClick}
          onPrevlick={handlePrevClick}
        />
      );
      break;
    default:
      componentToRender = null;
  }

  return (
    <>
      <div className="bg-black h-screen overflow-y-auto overflow-x-hidden sm:overflow-x-visible">
        <Navbar />
        <div className="flex flex-col items-center relative mx-auto mt-4 w-full sm:w-3/6 justify-center">
          <div className="mx-auto mt-[45px] ">
            <div className="w-full h-1/2 bg-indigo-700 bg-opacity-50 absolute top-0 left-0 blur-[100px] rounded-full pointer-events-none" />
            <div className="w-full h-1/2 bg-orange-300 bg-opacity-30 absolute bottom-0 left-0 blur-[100px] rounded-full pointer-events-none" />
          </div>

          <p className="absolute text-[#B36CFFCC] top-0 opacity-90 p-3 z-20">
            {currentStep} out of 5
          </p>
          <div className="flex gap-2">
            {steps.map((step) => {
              if (step <= currentStep) {
                return (
                  <div
                    key={step}
                    className="w-[30px] h-[7px] rounded-full bg-[#B36CFF]"
                  ></div>
                );
              }
              return (
                <div className="w-[30px] h-[7px] rounded-full bg-[#3E0082]"></div>
              );
            })}
          </div>
          {componentToRender}
        </div>
        <Footer />
      </div>
    </>
  );
}
