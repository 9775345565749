import React, { useState, useRef, useCallback } from 'react';
import { EditProjectCard } from './EditProjectCard';
import { EditSaleCard } from './EditSaleCard';
import { NetworkProps, networks } from './EditSaleCard/NetworkDropdown';
import { toast } from 'react-toastify';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';

interface EditModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  project: any;
}

const EditModal: React.FC<EditModalProps> = ({
  isOpen,
  setIsOpen,
  project,
}) => {
  const defaultLiquidityFactor = 0.5;
  const {
    admin: { updateProjectDetails },
  } = useFantomStarterAdminApi();

  const [openTab, setOpenTab] = useState(1);

  const uploadPreset =
    process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET ?? 'default_upload_preset';
  const cloudName =
    process.env.REACT_APP_CLOUDINARY_CLOUD_NAME ?? 'default_cloud_name';

  const [name, setName] = useState(project.name);
  const [twitterUrl, setTwitterUrl] = useState(project.twitter_url);
  const [discordUrl, setDiscordUrl] = useState(project.discord_url);
  const [mediumUrl, setMediumUrl] = useState(project.medium_url);
  const [websiteUrl, setWebsiteUrl] = useState(project.website_url);
  const [newTag, setNewTag] = useState<string>('');
  const [tags, setTags] = useState<string[]>(project.tags);
  const [logoUrl, setLogoUrl] = useState(project.logo_image_url);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [selectedNetwork, setSelectedNetwork] = useState<
    NetworkProps | undefined
  >(networks.find((network) => network.chainId === Number(project.chain)));
  const [raisingIn, setRaisingIn] = useState(project.pay_token_symbol);
  const [pricePerToken, setPricePerToken] = useState(
    project.sale_version === 8 || project.sale_version === 10
      ? Number(project.ratio_paytoken_per_idotoken) * defaultLiquidityFactor
      : Number(project.ratio_paytoken_per_idotoken)
  );
  const [maxmimumInvestment, setMaxmimumInvestment] = useState(
    Number(project.cap_individual_dollars)
  );
  const [totalRaiseGoal, setTotalRaiseGoal] = useState(
    Number(project.cap_total_dollars)
  );

  const [isSaving, setIsSaving] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    let logoImageUrl = project.logo_image_url;
    if (logoFile) {
      const data = new FormData();
      data.append('file', logoFile);
      data.append('upload_preset', uploadPreset);
      data.append('cloud_name', cloudName);
      data.append('folder', 'Cloudinary-React');
      try {
        const response = await fetch(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
          {
            method: 'POST',
            body: data,
          }
        );
        const jsonData = await response.json();
        logoImageUrl = await jsonData.secure_url;
      } catch (error) {
        console.error('Error uploading image: ', error);
      }
    }
    if (!selectedNetwork) return;
    console.log({
      project_id: project.id,
      name: name,
      tags: tags,
      logo_image_url: logoImageUrl,
      chain: selectedNetwork.chainId,
      pay_token_symbol: raisingIn,
      cap_total_dollars: totalRaiseGoal,
      cap_individual_dollars: maxmimumInvestment,
      ratio_paytoken_per_idotoken:
        project.sale_version === 8 || project.sale_version === 10
          ? pricePerToken / defaultLiquidityFactor
          : pricePerToken,
    });
    const response = await updateProjectDetails({
      id: project.id,
      project_id: project.id,
      name: name,
      tags: tags,
      logo_image_url: logoImageUrl,
      chain: selectedNetwork.chainId,
      pay_token_symbol: raisingIn,
      cap_total_dollars: totalRaiseGoal,
      cap_individual_dollars: maxmimumInvestment,
      ratio_paytoken_per_idotoken:
        project.sale_version === 8 || project.sale_version === 10
          ? pricePerToken / defaultLiquidityFactor
          : pricePerToken,
    });
    setIsSaving(false);
    if (response.status === 200) {
      toast.success('Updated Project Details!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      window.location.reload();
    } else {
      toast.error('Failed to update details!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [
    name,
    tags,
    project.id,
    project.logo_image_url,
    selectedNetwork,
    raisingIn,
    pricePerToken,
    maxmimumInvestment,
    totalRaiseGoal,
    updateProjectDetails,
    logoFile,
    uploadPreset,
    cloudName,
    project.sale_version,
  ]);

  const handleDismiss = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <>
      {isOpen ? (
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-transparent outline-none focus:outline-none">
                <div
                  className={`MainFrame w-[416px] p-[30px] bg-zinc-900 rounded-[30px] flex-col justify-start items-start gap-[30px] inline-flex`}
                >
                  <div className="SegmentedControl self-stretch p-0.5 bg-zinc-500 bg-opacity-25 rounded-[20px] justify-center items-center inline-flex">
                    <button
                      onClick={() => setOpenTab(1)}
                      className={`Button1 grow shrink basis-0 self-stretch p-2.5 ${
                        openTab === 1
                          ? 'bg-purple-500 text-black'
                          : 'text-white '
                      } rounded-[20px] justify-start items-center flex`}
                    >
                      <div className="Label grow shrink basis-0 h-[18px] text-center  text-base font-semibold leading-[18px]">
                        Edit project card
                      </div>
                    </button>
                    <button
                      onClick={() => setOpenTab(2)}
                      className={`Button2 grow shrink basis-0 self-stretch p-2.5 ${
                        openTab === 2
                          ? 'bg-purple-500 text-black'
                          : 'text-white '
                      } rounded-[20px] justify-start items-center flex`}
                    >
                      <div className="Label grow shrink basis-0 h-[18px] text-center  text-base font-semibold leading-[18px]">
                        Edit sale card
                      </div>
                    </button>
                  </div>
                  <div
                    className={`${openTab === 1 ? 'block' : 'hidden'} w-full`}
                  >
                    <EditProjectCard
                      project={project}
                      name={name}
                      setName={setName}
                      tags={tags}
                      setTags={setTags}
                      logoFile={logoFile}
                      setLogoFile={setLogoFile}
                      logoUrl={logoUrl}
                      setLogoUrl={setLogoUrl}
                    />
                  </div>

                  <div
                    className={`${openTab === 2 ? 'block' : 'hidden'} w-full`}
                  >
                    <EditSaleCard
                      project={project}
                      raisingIn={raisingIn}
                      setRaisingIn={setRaisingIn}
                      pricePerToken={pricePerToken}
                      setPricePerToken={setPricePerToken}
                      maxmimumInvestment={maxmimumInvestment}
                      setMaxmimumInvestment={setMaxmimumInvestment}
                      totalRaiseGoal={totalRaiseGoal}
                      setTotalRaiseGoal={setTotalRaiseGoal}
                      selectedNetwork={selectedNetwork}
                      setSelectedNetwork={setSelectedNetwork}
                    />
                  </div>
                  <div className="Frame5702 self-stretch justify-end gap-2 items-start inline-flex">
                    <button
                      onClick={() => handleDismiss()}
                      className="Button3 px-[18px] py-2.5 rounded-[60px]  justify-start items-center gap-1.5 flex"
                    >
                      <div className="Label text-center text-purple-500 text-sm font-semibold leading-[18px]">
                        Cancel
                      </div>
                    </button>
                    <button
                      onClick={() => handleSave()}
                      disabled={isSaving}
                      className={`${isSaving ? 'cursor-wait' : 'cursor-pointer'} Button1 px-[18px] py-2.5 bg-purple-500 rounded-[20px] justify-center items-center gap-1.5 flex`}
                    >
                      <div className="Label text-center text-black text-sm font-semibold leading-[18px]">
                        {isSaving ? 'Saving...' : 'Save'}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            ref={modalRef}
            className="fixed inset-0 z-40 bg-black bg-opacity-70 backdrop-blur-[2px]"
          />
        </div>
      ) : null}
    </>
  );
};

export default EditModal;
