import React, { useEffect, useState } from 'react';
import FantomStarterLogoImg from '../../assets/img/FantomStarterLogo.png';
import WalletImg from '../../assets/img/wallet.svg';
import useWeb3Provider from '../../hooks/useWeb3Provider';
import useFantomStarterAdminApi, {
  FANTOM_STARTER_ADMIN_API_URL,
  FANTOM_STARTER_ADMIN_METHODS,
} from '../../hooks/useFantomStarterAdminApi';
import useApiData from '../../hooks/useApiData';
import useToken from '../../hooks/useToken';
import Spacer from '../../components/Spacer/Spacer';
import { Typo2 } from '../../components';
import { useWeb3Modal } from '@web3modal/wagmi/react';

const Login: React.FC<any> = () => {
  const { open } = useWeb3Modal();
  const { signer } = useWeb3Provider();
  const { setToken } = useToken();
  const {
    getUser,
    registerUser,
    admin: { login },
  } = useFantomStarterAdminApi();
  const { apiData } = useApiData();
  const loginResult =
    apiData[FANTOM_STARTER_ADMIN_API_URL + FANTOM_STARTER_ADMIN_METHODS.LOGIN];
  const now = Date.now();

  const [account, setAccount] = useState<string | undefined>(undefined);
  const [isExistingUser, setIsExistingUser] = useState(false);

  useEffect(() => {
    if (signer) {
      signer.getAddress().then((address) => {
        setAccount(address);
      });
    }
  }, [signer]);

  useEffect(() => {
    if (account) {
      getUser(account).then((result) => {
        if (result?.data?.id) {
          setIsExistingUser(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <>
      <div className="w-full min-h-screen flex justify-center items-center bg-black px-4 md:px-8 lg:px-16 md:py-16">
        <div className="w-full max-w-3xl bg-black relative">
          <div className="w-full h-1/2 bg-violet-500 bg-opacity-50 absolute top-0 left-0 blur-[100px] rounded-full pointer-events-none" />

          <div className="w-full md:w-[357px] h-80 bg-white bg-opacity-5 rounded-[20px] border border-violet-500 border-opacity-50 mx-auto flex flex-col justify-center items-center p-6 md:mt-4 relative">
            <img
              className={`w-40 h-40 absolute left-1/2 top-[-100px] transform -translate-x-1/2`}
              src={FantomStarterLogoImg}
              alt="new"
            />

            <div className="text-violet-400 text-2xl font-semibold text-center mb-4">
              Create your project
            </div>
            <div className="w-[232px] text-neutral-400 text-base font-normal text-center mb-6">
              Connect your wallet before you proceed. This step is free.
            </div>

            <button
              className="w-full h-12 px-4 bg-gradient-to-br from-purple-500 to-indigo-700 rounded-[20px] shadow text-white font-semibold flex justify-center items-center gap-2 disabled:opacity-70"
              disabled={!!account}
              onClick={() => !account && open()}
            >
              <img src={WalletImg} alt="empty_wallet" />
              <div> {account ? 'Wallet connected' : 'Connect wallet'}</div>
            </button>

            {account && (
              <div className="absolute bottom-5 w-full px-4 flex flex-col justify-center items-center gap-4">
                {!isExistingUser ? (
                  <button
                    className="w-full h-12 px-4 bg-gradient-to-br from-purple-500 to-indigo-700 rounded-[20px] shadow text-white font-semibold flex justify-center items-center gap-2 disabled:opacity-70"
                    onClick={() =>
                      registerUser(account).then((result) => {
                        if (result?.data?.status === 'created') {
                          setIsExistingUser(true);
                        }
                      })
                    }
                  >
                    Connect wallet
                  </button>
                ) : (
                  <button
                    className="w-full h-12 px-4 bg-gradient-to-br from-purple-500 to-indigo-700 rounded-[20px] shadow text-white font-semibold flex justify-center items-center gap-2 disabled:opacity-70"
                    onClick={() =>
                      signer
                        ?.signMessage(
                          `FantomStarter-Admin-Login-${account}-${now.toString()}`
                        )
                        .then((signature) =>
                          login(account, signature, now).then((result) => {
                            setToken(result?.data?.token);
                          })
                        )
                    }
                  >
                    Sign Transaction to Login
                  </button>
                )}
              </div>
            )}

            <Spacer />
            {loginResult?.error?.response?.data?.fullError && (
              <>
                <Typo2 style={{ color: 'red' }}>
                  LoginError: {loginResult?.error?.response?.data?.fullError}
                </Typo2>
              </>
            )}
          </div>

          <div className="w-full h-1/2 bg-orange-300 bg-opacity-30 absolute bottom-0 left-0 blur-[100px] rounded-full pointer-events-none" />
        </div>
      </div>
    </>
  );
};

export default Login;
