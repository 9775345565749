import styled from 'styled-components';
import { mediaExact, mediaTill, MediaSizes, mediaFrom } from '../index';

export const Grid = styled.div<{ plain?: boolean }>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  ${(props: any) => !props.plain && mediaExact.xs(`padding: 1rem`)}
  ${(props: any) => !props.plain && mediaExact.sm(`padding: 1.4rem`)}
  ${(props: any) => !props.plain && mediaExact.md(`padding: 1.8rem`)}
  ${(props: any) => !props.plain && mediaExact.lg(`padding: 2rem`)}
`;

export const Row = styled.div<{ align?: string; justify?: string }>`
  display: flex;
  box-sizing: border-box;

  align-items: ${(props) => props.align || 'flex-start'};
  justify-content: ${(props) => props.justify || 'flex-start'};
`;

export const Column = styled.div<{ align?: string; justify?: string }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  align-items: ${(props) => props.align || 'flex-start'};
  justify-content: ${(props) => props.justify || 'flex-start'};
`;

export const Item = styled.div<{
  size?: number;
  collapseGTE?: MediaSizes;
  collapseLTE?: MediaSizes;
}>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex: ${(props) => props.size};
  ${(props) =>
    props.collapseLTE && mediaTill[props.collapseLTE](`display: none`)}
  ${(props) =>
    props.collapseGTE && mediaFrom[props.collapseGTE](`display: none`)}
`;
