import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect, useCallback } from 'react';

interface OnChangePluginProps {
  getData: (data: any) => void;
}

export const OnChangePlugin = ({ getData }: OnChangePluginProps) => {
  const [editor] = useLexicalComposerContext();

  const handleData = (data: any) => {
    getData(data);
  };
  useEffect(() => {
    return editor.registerUpdateListener((listener) => {
      handleData(listener.editorState.toJSON());
    });
  }, [editor]);

  return null;
};

export default OnChangePlugin;
