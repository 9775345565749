import { useEffect, useRef } from 'react';

const useOutsideClick = (ref: any, contentRef: any, onClose: any) => {
  const handleClickOutside = (event: any) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !contentRef.current.contains(event.target)
    ) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, contentRef, onClose]);
};

export default useOutsideClick;
